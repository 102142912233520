import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MapsDialog from './MapsDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg.MuiSvgIcon-root': {
      width: 14,
      height: 14,
    },
    '& .MuiRating-root': {
      color: '#fec8ad',
    },
    '& .MuiRating-iconEmpty': {
      color: '#fec8ad',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      fontSize: 18,
      fontWeight: 600,
    }
  },
  bookmarkedIcon: {
    filter: 'brightness(10)',
    opacity: '.9',
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
    '& div': {
      color: '#e5bba7',
      flex: 1,
    },
    '& p': {
      fontSize: 13,
    }
  },
  mapLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  distance: {
    paddingLeft: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(3),
    '& a': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #fff',
      padding: theme.spacing(2),
      color: '#fff',
      marginRight: -1,
    },
    '& p': {
      fontSize: 13,
    }
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: 14,
      color: '#e5bba7',
      marginLeft: theme.spacing(1),
    }
  }
}));

const ExpandedContent = ({
  item, handleOpenRating, isBookmarked, handleBookmark, handleDeleteBookmark
}) => {
  const classes = useStyles();
  const [isMapOpen, setIsMapOpen] = useState(false);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
            <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
          </svg>
          <Typography>{item.name}</Typography>
        </div>
        <div className={classes.ratingContainer}>
          <IconButton className={classes.summaryfavoriteBtn} aria-label="favorite" onClick={() => (isBookmarked ? handleDeleteBookmark(item.id) : handleBookmark(item.id))}>
            <img src={isBookmarked ? '/static/icons/favoriteIcon.svg' : '/static/icons/bookmark-empty.svg'} alt="" className={isBookmarked ? classes.bookmarkedIcon : ''} />
          </IconButton>
          <div onClick={() => handleOpenRating(item)} className={classes.ratingContainer}>
            <Rating
              readOnly
              name={`${item.name} rating`}
              value={item.rating ? item.rating : 0}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
            <Typography>{`(${item.ratingVotes})`}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div>
          <Typography>{item.type}</Typography>
          <Typography
            className={classes.mapLink}
            onClick={() => setIsMapOpen(true)}
          >
            {item.street}
          </Typography>
          {/*
            <Typography>Ouverture :</Typography>
            <Typography>{`Du mardi au dimanche: ${item.openingHours}`}</Typography>
          */}
        </div>
        <Typography className={classes.distance}>{`(${item.distance})`}</Typography>
      </div>
      <div className={classes.footer}>
        {(item.site !== 'N.C' || !item.site) && (
          <a href={item.site} target="_blank" rel="noreferrer noopener"><Typography>Site Web</Typography></a>
        )}
        {(item.phone !== 'N.C' || !item.phone) && (
          <a href={`tel:${item.phone}`}><Typography>Appeler</Typography></a>
        )}
      </div>
      <MapsDialog item={item} isOpen={isMapOpen} close={() => setIsMapOpen(false)} />
    </>
  );
};

ExpandedContent.propTypes = {
  item: PropTypes.object.isRequired,
  handleOpenRating: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool,
  handleBookmark: PropTypes.func,
  handleDeleteBookmark: PropTypes.func,
};

export default ExpandedContent;
