import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ light }) => {
  return (
    <img style={{ width: 220 }} src="static/logo.svg" alt="hector groom logo" />
  );
};

Logo.propTypes = {
  light: PropTypes.bool,
};

export default Logo;
