import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SecondaryLayout from '../../layouts/SecondaryLayout';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(2),
    color: '#ce9578',
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    '& p': {
      fontSize: 20,
      fontWeight: 600,
    }
  }
}));

const ReportProblemView = () => {
  const classes = useStyles();
  const { propertyId } = useParams();
  const { hostingId } = useParams();

  return (
    <SecondaryLayout
      breadcrumbTitle="Contactez votre gestionnaire"
      breadcrumbIcon={<img src="/static/icons/flagIcon.svg" alt="" />}
      title="Un problème, une discussion, une solution."
    >
      <Link to={`/app/${propertyId}-${hostingId}/messages/new`} className={classes.link}>
        <Typography>Nouveau sujet</Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
          <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M.5.5h22" />
        </svg>
      </Link>
      <Link to={`/app/${propertyId}-${hostingId}/messages`} className={classes.link}>
        <Typography>Historique</Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
          <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M.5.5h22" />
        </svg>
      </Link>
    </SecondaryLayout>
  );
};

export default ReportProblemView;
