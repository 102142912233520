import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from './Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& fieldset': {
      width: '100%',
    }
  },
  legend: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    width: '100%',
    color: '#c7754c',
    textTransform: 'uppercase',
    borderBottom: '1px solid #c7754c',
  },
  filtersGroup: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& label': {
      borderBottom: '1px solid #c7754c',
      padding: '8px 0',
      color: '#c7754c',
      margin: 0,
      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 10,
      },
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  }
}));

const FiltersCheckboxes = ({ filters, handleChangeFilters }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.legend}>FILTRES:</FormLabel>
        <FormGroup className={classes.filtersGroup}>
          { filters && Object.keys(filters).map((key, index) => (
            <FormControlLabel
              key={key}
              control={(
                <Checkbox
                  checked={filters[key]}
                  onChange={handleChangeFilters}
                  name={key}
                />
              )}
              label={key}
            />
          ))}
          {/* eslint-disable */
          /*
            <FormControlLabel
              control={<Checkbox checked={filters.restaurants} onChange={handleChangeFilters} name="restaurants" />}
              label="Restaurants"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.bars} onChange={handleChangeFilters} name="bars" />}
              label="Bars"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.fastFood} onChange={handleChangeFilters} name="fastFood" />}
              label="Fast-food"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.boulangeries} onChange={handleChangeFilters} name="boulangeries" />}
              label="Boulangeries"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.vegan} onChange={handleChangeFilters} name="vegan" />}
              label="Vegan"
            />
          */
          /* eslint-disable */}
        </FormGroup>
      </FormControl>
    </div>
  );
};

FiltersCheckboxes.propTypes = {
  filters: PropTypes.object,
  handleChangeFilters: PropTypes.func.isRequired,
};

export default FiltersCheckboxes;
