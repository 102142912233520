import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import UserDrawer from './UserDrawer';
import NotificationsDrawer from './NotificationsDrawer';
import BackButton from './BackButton';
import { toggleNotificationsDrawer, toggleUserDrawer } from '../redux/reducers/uiReducer';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  leftSide: {
    flex: 1,
  },
  notificationsBadge: {
    '& span': {
      marginTop: theme.spacing(1),
      background: '#ff6e6e',
      color: '#fff',
      fontSize: 13,
    }
  },
}));

const Header = ({ light, backBtnHistory }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyId } = useParams();
  const { hostingId } = useParams();

  const notifications = useSelector((state) => state.auth.notifications);
  const isNotificationsDrawerOpen = useSelector((state) => state.ui.isNotificationsDrawerOpen);
  const isUserDrawerOpen = useSelector((state) => state.ui.isUserDrawerOpen);
  const unreadMessages = useSelector((state) => state.auth.unreadMessages);

  return (
    <>
      <div className={classes.toolBar}>
        <div className={classes.leftSide}>
          <BackButton backBtnHistory={backBtnHistory} />
        </div>
        {/* eslint-disable */
        /*{notifications.length && (
          <IconButton aria-label="notifications" onClick={() => dispatch(toggleNotificationsDrawer())}>
            <Badge badgeContent={notifications.length} className={classes.notificationsBadge} />
          </IconButton>
        )}*/
        /* eslint-disable */}
        {!location.pathname.includes('/messages') && (
          <IconButton aria-label="messages" onClick={() => navigate(`/app/${propertyId}-${hostingId}/messages`)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18">
              <path fill={light ? '#be7e5e' : '#fff'} d="M1.886 16.22V4.198l13.516 10.673L28.92 4.198V16.22zM28.919 1.08v1.76L15.402 13.514 1.886 2.84V1.08zM.805 0v17.3H30V0z" />
            </svg>
            <Badge badgeContent={unreadMessages} className={classes.notificationsBadge} />
          </IconButton>
        )}
        <IconButton aria-label="account" onClick={() => dispatch(toggleUserDrawer())}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
            <path fill={light ? '#be7e5e' : '#fff'} d="M10.388 3.997A2.916 2.916 0 0 1 7.46 6.925a2.916 2.916 0 0 1-2.928-2.928c0-1.58 1.301-2.928 2.928-2.928a2.916 2.916 0 0 1 2.928 2.928zm-6.925 0c0 2.23 1.766 4.043 3.997 4.043 2.23 0 3.997-1.812 3.997-4.043a3.997 3.997 0 1 0-7.994 0z" />
            <path fill={light ? '#be7e5e' : '#fff'} d="M7.46 9.295c4.183 0 7.576 3.486 7.483 7.622 0 .512-1.07.512-1.07 0 .047-3.578-2.788-6.553-6.413-6.553-3.625 0-6.46 2.975-6.414 6.553.047.512-1.069.512-1.069 0-.093-4.136 3.3-7.622 7.483-7.622z" />
          </svg>
        </IconButton>
      </div>
      <UserDrawer isOpen={isUserDrawerOpen} toggleDrawer={() => dispatch(toggleUserDrawer())} />
      <NotificationsDrawer
        isDrawerOpen={isNotificationsDrawerOpen}
        toggleDrawer={() => dispatch(toggleNotificationsDrawer())}
      />
    </>
  );
};

Header.propTypes = {
  light: PropTypes.bool,
  backBtnHistory: PropTypes.any,
};

export default Header;
