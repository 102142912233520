import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  root: {
  },
}));

const CategoriesExternalLinks = ({ categoryId }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {(categoryId === '8' || categoryId === '18') && (
        <>
          <a href="https://m.uber.com/ul" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="uber eats"
            >
              <img src="/static/icons/ubereatIcon.svg" alt="uber eats" />
            </IconButton>
          </a>
          <a href="https://deliveroo.co.uk/" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="deliveroo"
            >
              <img src="/static/icons/deliverooIcon.svg" alt="deliveroo" />
            </IconButton>
          </a>
        </>
      )}
      {(categoryId === '9' || categoryId === '19') && (
        <>
          <a href="https://m.uber.com/ul" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="uber"
            >
              <img src="/static/icons/uberIcon.svg" alt="uber" />
            </IconButton>
          </a>
          <a href="https://www.g7.fr/" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="g7"
            >
              <img src="/static/icons/g7Icon.svg" alt="g7" />
            </IconButton>
          </a>
        </>
      )}
      {(categoryId === '13' || categoryId === '23') && (
        <a href="https://www.doctolib.com/" target="_blank" rel="noreferrer">
          <IconButton
            aria-label="doctolib"
          >
            <img src="/static/icons/doctolibIcon.svg" alt="doctolib" />
          </IconButton>
        </a>
      )}
      {(categoryId === '15') && (
        <>
          <a href="https://www.velib-metropole.fr/" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="velib"
            >
              <img src="/static/icons/icon_velib.svg" alt="velib" />
            </IconButton>
          </a>
          <a href="https://m.bolt.eu/" target="_blank" rel="noreferrer">
            <IconButton
              aria-label="bolt"
            >
              <img src="/static/icons/icon_bolt.svg" alt="bolt" />
            </IconButton>
          </a>
        </>
      )}
    </div>
  );
};

CategoriesExternalLinks.propTypes = {
  categoryId: PropTypes.any,
};

export default CategoriesExternalLinks;
