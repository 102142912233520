import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import authService from '../services/authService';
import { login, clearFirstRouteRequested } from '../redux/reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  form: {
    '& label': {
      color: '#fff',
    }
  },
  input: {
    color: '#fff',
    borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    '&::before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    }
  },
  submitBtn: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    height: 91,
  },
  changeAuthPage: {
    marginTop: theme.spacing(4),
    color: '#fff',
    textAlign: 'center',
  },
  btnLoading: {
    color: 'rgba(200,200,200,.8)',
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email est requis').email().label('Email'),
  password: Yup.string().required('Mot de passe requis').min(3).label('Mot de passe'),
});

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { firstRouteRequested } = useSelector((state) => state.auth);

  const [loginFailed, setLoginFailed] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async ({ email, password }, { setSubmitting }) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const result = await authService.login(formData);
    if (!result.ok) {
      setSubmitting(false);
      return setLoginFailed(true);
    }

    setLoginFailed(false);
    dispatch(login(result.data.data));
    if (firstRouteRequested) {
      navigate(firstRouteRequested, { replace: true });
    } else {
      navigate('/app', { replace: true });
    }
    dispatch(clearFirstRouteRequested());
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        {loginFailed && (
          <Box my={2} color="error.main">
            <Typography>Email ou mot de passe invalide</Typography>
          </Box>
        )}

        <TextField
          InputProps={{
            className: classes.input
          }}
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="EMAIL"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <TextField
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility style={{ color: '#fff' }} /> : <VisibilityOff style={{ color: '#fff' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="MOT DE PASSE"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
        />

        <Button
          type="submit"
          className={classes.submitBtn}
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? <CircularProgress className={classes.btnLoading} /> : 'ENTRER'}
        </Button>
      </form>
      <Link to="/register" className={classes.changeAuthPage}>Vous n&apos;avez pas encore de compte? Créer un nouveau</Link>
    </>
  );
};

export default LoginView;
