import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -100,
    marginBottom: theme.spacing(6),
    width: '100%',
    height: 400,
    [theme.breakpoints.up('sm')]: {
      height: 600,
    },
  }
}));

const containerStyle = {
  width: '100%',
  height: '100%',
};

function MyComponent({ position }) {
  const classes = useStyles();

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
    >
      <div className={classes.root}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={15}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <Marker
            position={position}
          />
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

MyComponent.propTypes = {
  position: PropTypes.object.isRequired,
};

export default React.memo(MyComponent);
