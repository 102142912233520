import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import authService from '../services/authService';
import { login } from '../redux/reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  form: {
    '& label': {
      color: '#fff',
    }
  },
  input: {
    color: '#fff',
    textAlign: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    '&::before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    }
  },
  submitBtn: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    height: 91,
  },
  changeAuthPage: {
    marginTop: theme.spacing(4),
    color: '#fff',
    textAlign: 'center',
  },
  errorBox: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    padding: theme.spacing(2),
    borderRadius: 4,
  },
  btnLoading: {
    color: 'rgba(200,200,200,.8)',
  },
  formControlCheckbox: {
    marginTop: theme.spacing(3),
    marginRight: 0,
  },
  termsCheckbox: {
    alignSelf: 'flex-start',
    paddingTop: 0,
    color: '#fff !important',
  },
  termsText: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    color: '#fff',
  },
  link: {
    textDecoration: 'underline',
    color: '#fff',
  }
}));

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('Prénom est requis').label('Prénom'),
  lastname: Yup.string().required('Nom de Famille est requis').label('Nom de Famille'),
  email: Yup.string().required('Email est requis').email().label('Email'),
  phone: Yup.string().required('Téléphone est requis').label('Téléphone'),
  password: Yup.string().required('Mot de passe est requis').min(3).label('Mot de passe'),
  confirmPassword: Yup.string().required('Confirmez le mot de pass est requis').oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
  terms: Yup.boolean().required('Je dois accepter les "Mentions Légales" et la "Politique de Confidentialité"').oneOf([true], 'Je dois accepter les "Mentions Légales" et la "Politique de Confidentialité"')
});

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [registerFailed, setRegisterFailed] = useState();
  const [registerError, setRegisterError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword((prev) => !prev);
  };
  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async ({
    firstname, lastname, email, phone, password
  }, { setSubmitting }) => {
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);

    // console.log('submiting form');

    const result = await authService.register(formData);
    // console.log('register response', result);
    if (!result.ok || !result.data.success) {
      // #TODO error from backend should be a string not an object with multiple changing properties
      if (result.data.data.error?.email) {
        setRegisterError('Adresse email invalide');
      }
      setSubmitting(false);
      return setRegisterFailed(true);
    }

    setRegisterFailed(false);
    dispatch(login(result.data.data));
    return navigate('/app', { replace: true });
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      terms: false,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          InputProps={{
            className: classes.input,
          }}
          error={Boolean(formik.touched.firstname && formik.errors.firstname)}
          fullWidth
          helperText={formik.touched.firstname && formik.errors.firstname}
          label="PRÉNOM"
          margin="normal"
          name="firstname"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.firstname}
        />
        <TextField
          InputProps={{
            className: classes.input,
          }}
          error={Boolean(formik.touched.lastname && formik.errors.lastname)}
          fullWidth
          helperText={formik.touched.lastname && formik.errors.lastname}
          label="NOM DE FAMILLE"
          margin="normal"
          name="lastname"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.lastname}
        />
        <TextField
          InputProps={{
            className: classes.input,
          }}
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="EMAIL"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <TextField
          InputProps={{
            className: classes.input,
          }}
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          fullWidth
          helperText={formik.touched.phone && formik.errors.phone}
          label="TÉLÉPHONE"
          margin="normal"
          name="phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="tel"
          value={formik.values.phone}
        />
        <TextField
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility style={{ color: '#fff' }} /> : <VisibilityOff style={{ color: '#fff' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="MOT DE PASSE"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
        />
        <TextField
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatPassword}
                  onMouseDown={handleMouseDownRepeatPassword}
                >
                  {showRepeatPassword ? <Visibility style={{ color: '#fff' }} /> : <VisibilityOff style={{ color: '#fff' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          fullWidth
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          label="CONFIRMEZ LE MOT DE PASSE"
          margin="normal"
          name="confirmPassword"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type={showRepeatPassword ? 'text' : 'password'}
          value={formik.values.confirmPassword}
        />

        <FormControl error={Boolean(formik.touched.terms && formik.errors.terms)}>
          <FormControlLabel
            className={classes.formControlCheckbox}
            control={(
              <Checkbox
                checked={formik.values.terms}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="terms"
                color="primary"
                className={classes.termsCheckbox}
              />
            )}
            label={(
              <Typography>
                J&apos;ai lu et j&apos;accepte les
                &quot;Mentions Légales&quot; et la &quot;Politique de Confidentialité&quot;
              </Typography>
            )}
          />
          {
            Boolean(formik.touched.terms && formik.errors.terms)
            && (
              <FormHelperText>
                Je dois accepter les &quot;Mentions Légales&quot;
                et la &quot;Politique de Confidentialité&quot;
              </FormHelperText>
            )
          }
          <Typography className={classes.termsText}>
            {/* eslint-disable-next-line */}
            Je peux lire ici les <a className={classes.link} href="/mentions-legales" target="_blank">Mentions Légales</a> et la <a className={classes.link} href="/politique-de-confidentialite" target="_blank">Politique de Confidentialité</a>
          </Typography>
        </FormControl>

        {registerFailed && (
          <Box my={2} className={classes.errorBox}>
            {registerError ? (
              <Typography>{registerError}</Typography>
            ) : (
              <Typography>An error occurred</Typography>
            )}
          </Box>
        )}

        <Button
          type="submit"
          className={classes.submitBtn}
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? <CircularProgress className={classes.btnLoading} /> : 'ENTRER'}
        </Button>
      </form>
      <Link to="/login" className={classes.changeAuthPage}>Vous avez un compte? Connectez-vous.</Link>
    </>
  );
};

export default RegisterView;
