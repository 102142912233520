import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';

import GlobalStyles from './components/GlobalStyles';
import theme from './config/theme';
import routes from './routes';

import authStorage from './localStorage/auth';
import { syncUser, setFirstRouteRequested } from './redux/reducers/authReducer';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = authStorage.getToken();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location.pathname, { replace: true });
      dispatch(syncUser());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(setFirstRouteRequested(location.pathname));
  }, []);

  const routing = useRoutes(routes(isLoggedIn));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <CookieConsent
        location="bottom"
        cookieName="hectorgroomCookieConsent"
        style={{ background: '#c7754c' }}
        buttonText="J'accepte"
        buttonStyle={{
          color: '#4e503b',
          background: '#fff',
          fontSize: 16,
          padding: '8px 16px',
        }}
        enableDeclineButton
        declineButtonText="Rejeter"
        declineButtonStyle={{
          color: '#fff',
          background: 'transparent',
          border: '1px solid #fff',
          fontSize: 16,
          padding: '8px 16px',
        }}
      >
        Ce site Web utilise des cookies pour améliorer l&apos;expérience utilisateur.
      </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
