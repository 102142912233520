import React from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import PropertyLayout from './layouts/PropertyLayout';

import SelectPropertyView from './views/SelectPropertyView';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import DashboardView from './views/DashboardView';
import EssentialsView from './views/EssentialsView';
import {
  ReportProblemView,
  ReportProblemHistoryView
} from './views/ReportProblemView';
import CategoryView from './views/CategoryView';
import TermsConditionsView from './views/TermsConditionsView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import { MessagesView, SingleMessageView, MessageFormView } from './views/MessagesView';

const routes = (isLoggedIn) => [
  {
    path: '/app',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      { path: '/', element: <SelectPropertyView /> },
      {
        path: ':propertyId-:hostingId',
        element: <PropertyLayout />,
        children: [
          { path: '/', element: <DashboardView /> },
          { path: 'essentiels', element: <EssentialsView /> },
          {
            path: 'contactez-votre-gestionnaire',
            children: [
              { path: '/', element: <ReportProblemView /> },
              // { path: 'form', element: <MessageFormView /> },
              // { path: 'historique', element: <ReportProblemHistoryView /> },
            ]
          },
          { path: 'view/:category', element: <CategoryView /> },
          /*
          { path: 'sports-et-loisirs', element: <SportsHobbiesView /> },
          { path: 'services-du-quotidien', element: <DailyServicesView /> },
          { path: 'sante', element: <HealthView /> },
          { path: 'bien-etre', element: <WellBeingView /> },
          { path: 'services-a-la-persone', element: <PersonalServicesView /> },
          { path: 'cadeaux', element: <GiftsView /> },
          { path: 'location', element: <LocationView /> },
          { path: 'casiers-connectes', element: <ConnectedLockersView /> },
          */
          {
            path: 'messages',
            children: [
              { path: '/', element: <MessagesView /> },
              { path: 'view/:id', element: <SingleMessageView /> },
              { path: 'new', element: <MessageFormView /> },
            ]
          },
          { path: '*', element: <Navigate to="/" /> } // #TODO render not found page
        ],
      },
    ]
  },
  {
    path: '/mentions-legales',
    element: <TermsConditionsView />,
  },
  {
    path: '/politique-de-confidentialite',
    element: <PrivacyPolicyView />,
  },
  {
    path: '/',
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/app" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },
];

export default routes;
