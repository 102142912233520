import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    textAlign: 'center',
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
  },
  date: {
    color: '#e5bba7',
  },
  code: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 500,
  },
  snackbar: {
    bottom: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    background: '#fff',
    color: '#000',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    minWidth: 320,
  },
  snackbarInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackText: {
    fontSize: 14,
    marginTop: theme.spacing(2),
  }
}));

const CodeWifi = () => {
  const classes = useStyles();
  const codeWifi = useSelector((state) => state.ui.hostingInfo?.codeWifi);
  const networkWifi = useSelector((state) => state.ui.hostingInfo?.networkWifi);
  const [isCopied, setIsCopied] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCopied(false);
  };

  const handleCopyToClipboar = (text) => {
    copy(text);
    setIsCopied(true);
  };

  return (
    <>
      {codeWifi
        ? (
          <div className={classes.root} onClick={() => handleCopyToClipboar(codeWifi)}>
            <Typography className={classes.date}>Réseau WIFI</Typography>
            <Typography className={classes.code}>{networkWifi}</Typography>
            <br />
            <Typography className={classes.date}>Mot de pass WIFI</Typography>
            <Typography className={classes.code}>{codeWifi}</Typography>
          </div>
        )
        : (
          <div className={classes.root}>
            <Typography className={classes.date}>Aucun code disponible</Typography>
          </div>
        )}
      <Snackbar
        className={classes.snackbar}
        open={isCopied}
        onClose={handleClose}
        autoHideDuration={1200}
      >
        <div className={classes.snackbarInner} onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="43" viewBox="0 0 46 43">
            <g clipPath="url(#clip-6e1d1cf1-6ff4-4299-a294-e11f9f230ccd)">
              <path d="M41.953 21.864c0 11.065-9.003 20.063-20.063 20.063-11.06 0-20.064-9.003-20.064-20.063C1.826 10.804 10.83 1.8 21.89 1.8c6.384 0 12.08 3.003 15.756 7.664L21.89 29.341l-9.362-11.809a.487.487 0 0 0-.69-.079.487.487 0 0 0-.078.69l9.746 12.295a.492.492 0 0 0 .384.187c.147 0 .29-.068.384-.187l15.982-20.161a19.965 19.965 0 0 1 3.697 11.587zM45.94-.208a.492.492 0 0 0-.689.079l-6.975 8.8C34.412 3.884 28.506.812 21.89.812 10.283.811.842 10.257.842 21.864c0 11.607 9.441 21.048 21.048 21.048 11.607 0 21.048-9.441 21.048-21.048 0-4.627-1.507-8.91-4.047-12.385L46.02.481a.487.487 0 0 0-.079-.689z" />
            </g>
          </svg>
          <Typography className={classes.snackText}>COPIÉ</Typography>
        </div>
      </Snackbar>
    </>
  );
};

export default CodeWifi;
