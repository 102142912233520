import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import Loading from './Loading';
import useGestionnaires from '../hooks/useGestionnaires';

const staticMenus = [
  {
    name: 'Les essentiels',
    slug: 'essentiels',
    icon: 'keyIcon'
  },
];

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    borderRadius: 0,
    cursor: 'pointer',
    height: 181,
    '&:first-child, &:last-child:nth-child(even)': {
      height: 168,
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      gridColumn: '1 / span 2',
      '& p': {
        fontSize: 25,
      }
    },
  },
  title: {
    fontWeight: 600,
    color: '#000',
    fontSize: 22,
    width: '100%',
    lineHeight: 1.125,
  },
  underline: {
    marginTop: theme.spacing(1),
    borderBottom: '1px solid #000',
    display: 'block',
    width: '20%',
  }
}));

const HomeMenu = () => {
  const classes = useStyles();
  const dynamicMenus = useSelector((state) => state.ui.menus);
  const error = useSelector((state) => state.ui.menus_error);
  const { uniqueGestionnaires } = useGestionnaires();

  if (!dynamicMenus && !error) {
    return (
      <Loading />
    );
  }

  return (
    <>
      {error ? (
        <Typography style={{ marginTop: '4rem' }}>{error}</Typography>
      ) : (
        <Box className={classes.menuContainer}>
          <Link to="essentiels" className={classes.card}>
            <img src="/static/icons/keyIcon.svg" alt="" />
            <Typography className={classes.title}>
              Les essentiels
              <span className={classes.underline} />
            </Typography>
          </Link>
          {dynamicMenus.map((link) => (
            <Link key={`link-${link.name}`} to={`view/${link.slug}`} className={classes.card}>
              <img src={link.icon} alt="" />
              <Typography className={classes.title}>
                {link.name}
                <span className={classes.underline} />
              </Typography>
            </Link>
          ))}
          {uniqueGestionnaires.length > 0 && (
            <Link to="contactez-votre-gestionnaire" className={classes.card}>
              <img src="/static/icons/flagIcon.svg" alt="" />
              <Typography className={classes.title}>
                Contactez votre gestionnaire
                <span className={classes.underline} />
              </Typography>
            </Link>
          ) }
        </Box>
      )}
    </>
  );
};

export default HomeMenu;
