import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import PublicLayout from '../layouts/PublicLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    '& p.MuiTypography-gutterBottom': {
      marginBottom: '1rem',
    }
  },
  link: {
    color: '#c7754c'
  },
  title: {
    fontFamily: 'Anke',
  },
  ul: {
    marginLeft: 32,
    '& > li': {
      marginBottom: '0.75rem',
    }
  }
}));

const TermsConditionsView = () => {
  const classes = useStyles();
  return (
    <PublicLayout
      title="Mentions Légales"
    >
      <Container className={classes.container} maxWidth="md">
        <Box pb={3}>
          <Typography className={classes.title} variant="h2" gutterBottom>1. Présentation du site</Typography>
          <Typography>
            En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour
            la confiance dans l’économie numérique, il est précisé aux utilisateurs
            du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            l’identité des différents intervenants dans le cadre de sa réalisation
            et de son suivi:
          </Typography>
        </Box>

        <Typography>
          <strong>Propriétaire: </strong>
          FULTON - 39 avenue George V, 75008 Paris
        </Typography>
        <Typography>
          <strong>Responsable publication: </strong>
          FULTON - 39 avenue George V, 75008 Paris
        </Typography>
        <Typography>
          <strong>Conception &amp; développement: </strong>
          ADDB
          &nbsp;
          <a className={classes.link} href="https://www.addb.fr/">https://www.addb.fr/</a>
        </Typography>
        <Typography>
          <strong>Hébergeur: </strong>
          Cloudways
        </Typography>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>2. Conditions générales d’utilisation du site et des services proposés</Typography>
          <Typography gutterBottom>
            L’utilisation du site implique l’acceptation pleine et
            entière des conditions générales d’utilisation ci-après
            décrites. Ces conditions d’utilisation sont susceptibles
            d’être modifiées ou complétées à tout moment, les utilisateurs du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            sont donc invités à les consulter de manière régulière.
          </Typography>
          <Typography gutterBottom>
            Ce site est normalement accessible à tout moment aux utilisateurs.
            Une interruption pour raison de maintenance technique peut être toutefois
            décidée par Fulton, qui s’efforcera alors de communiquer préalablement aux
            utilisateurs les dates et heures de l’intervention.
          </Typography>
          <Typography gutterBottom>
            Le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            est mis à jour régulièrement par Fulton.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>3. Description des services fournis</Typography>
          <Typography gutterBottom>
            Le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            a pour objet de fournir une information concernant l’ensemble
            des activités de l’association.
          </Typography>
          <Typography gutterBottom>
            Fulton s’efforce de fournir sur le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            informations aussi précises que possible. Toutefois, il ne
            pourra être tenu pour responsable des omissions, des inexactitudes
            et des carences dans la mise à jour, qu’elles soient de son fait
            ou du fait des tiers partenaires qui lui fournissent ces informations.
          </Typography>
          <Typography gutterBottom>
            Toutes les informations indiquées sur le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs,
            les renseignements figurant sur le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications
            ayant été apportées depuis leur mise en ligne.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>4. Limitations contractuelles sur les données techniques</Typography>
          <Typography gutterBottom>
            Le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            ne pourra être tenu responsable de dommages matériels liés à son utilisation.
            De plus, l’utilisateur du site s’engage à y accéder en utilisant un matériel
            récent, ne contenant pas de virus et avec un navigateur de dernière
            génération mis-à-jour.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>5. Propriété intellectuelle et contrefaçons</Typography>
          <Typography gutterBottom>
            Fulton est propriétaire des droits de propriété intellectuelle ou détient
            les droits d’usage sur tous les éléments accessibles sur le site, notamment
            les textes, images, graphismes, logo, icônes, sons, logiciels.
          </Typography>
          <Typography gutterBottom>
            Toute reproduction, représentation, modification, publication, adaptation
            de tout ou partie des éléments du site, quel que soit le moyen ou le
            procédé utilisé, est interdite, sauf autorisation écrite préalable du Cérémé.
          </Typography>
          <Typography gutterBottom>
            Toute exploitation non autorisée du site ou de l’un quelconque des
            éléments qu’il contient sera considérée comme constitutive d’une
            contrefaçon et poursuivie conformément aux dispositions des articles
            L.335-2 et suivants du Code de Propriété Intellectuelle.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>6. Limitations de responsabilité</Typography>
          <Typography gutterBottom>
            Fulton ne pourra être tenu responsable des dommages directs et
            indirects causés au matériel de l’utilisateur, lors de l’accès au site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            et résultant soit de l’utilisation d’un matériel ne répondant pas aux
            spécifications indiquées au point 4, soit de l’apparition d’un bug ou
            d’une incompatibilité.
          </Typography>
          <Typography gutterBottom>
            Fulton ne pourra également être tenu responsable des dommages indirects consécutifs
            à l’utilisation du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            .
          </Typography>
          <Typography gutterBottom>
            Des espaces interactifs (possibilité d’adresser une demande dans
            l’espace contact) sont à la disposition des utilisateurs. Fulton se réserve
            le droit de supprimer, sans mise en demeure préalable, tout contenu déposé
            dans cet espace qui contreviendrait à la législation applicable en France,
            en particulier aux dispositions relatives à la protection des données. Le
            cas échéant, Fulton se réserve également la possibilité de mettre en cause
            la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de
            message à caractère raciste, injurieux, diffamant, ou pornographique, quel
            que soit le support utilisé (texte, photographie…).
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>7. Gestion des données personnelles</Typography>
          <Typography gutterBottom>
            En France, les données personnelles sont notamment protégées par la loi
            n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article
            L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
          </Typography>
          <Typography gutterBottom>
            A l’occasion de l’utilisation du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            , peuvent être recueillies : l’URL des liens par l’intermédiaire desquels
            l’utilisateur a accédé au site, le fournisseur d’accès de l’utilisateur, l’adresse
            de protocole Internet (IP) de l’utilisateur.
          </Typography>
          <Typography gutterBottom>
            En tout état de cause Fulton ne collecte des informations personnelles
            relatives à l’utilisateur que pour le besoin de certains services proposés
            par le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            .
          </Typography>
          <Typography gutterBottom>
            L’utilisateur fournit ces informations en toute connaissance de
            cause, notamment lorsqu’il procède par lui-même à leur saisie. Il
            est alors précisé à l’utilisateur du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            l’obligation ou non de fournir ces informations.
          </Typography>
          <Typography gutterBottom>
            Conformément aux dispositions des articles 38 et suivants de
            la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux
            fichiers et aux libertés, tout utilisateur dispose d’un droit
            d’accès, de rectification et d’opposition aux données personnelles
            le concernant, en effectuant sa demande écrite et signée, accompagnée
            d’une copie du titre d’identité avec signature du titulaire de la
            pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.
          </Typography>
          <Typography gutterBottom>
            Aucune information personnelle de l’utilisateur du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée
            ou vendue sur un support quelconque à des tiers.
          </Typography>
          <Typography gutterBottom>Le site n’est pas déclaré à la CNIL.</Typography>
          <Typography gutterBottom>
            Les bases de données sont protégées par les dispositions de la
            loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars
            1996 relative à la protection juridique des bases de données.
          </Typography>

          <Typography style={{ marginTop: 48 }} className={classes.title} variant="h3" gutterBottom>Règlement Général sur la Protection des données</Typography>
          <Typography gutterBottom>
            Le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            ne recueille pas d’informations personnelles, autrement que celles communiquées
            librement et volontairement par les utilisateurs via l’adresse mail de
            contact: info@fulton.fr.
          </Typography>
          <Typography gutterBottom>
            Les informations que l’utilisateur y communique sont pour le strict
            usage interne du Cérémé. Elles ne sont pas transférées en dehors de l’UE
            et sont conservées pendant une durée de 5 ans.
          </Typography>
          <Typography gutterBottom>
            Le RGPD renforce les droits d’accès, de rectification, d’opposition,
            d’effacement, de portabilité́ et de limitation du traitement des données
            que l’utilisateur communique. A ce titre l’utilisateur peut exercer ces
            droits par simple demande à l’adresse info@fulton.fr. Fulton s’engage à
            lui répondre dans un maximum d’un mois.
          </Typography>
          <Typography gutterBottom>
            L’utilisateur dispose également du droit d’introduire une réclamation
            auprès de la Commission nationale de l’informatique et des libertés (CNIL):
            &nbsp;
            <a className={classes.link} href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>
            .
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>8. Liens hypertextes et cookies</Typography>
          <Typography gutterBottom>
            Le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            contient un certain nombre de liens hypertextes vers d’autres sites,
            mis en place avec l’autorisation de Clai. Cependant, Fulton n’a pas
            la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera
            en conséquence aucune responsabilité de ce fait.
          </Typography>
          <Typography gutterBottom>
            La navigation sur le site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur
            de l’utilisateur.
          </Typography>
          <Typography style={{ marginTop: 32 }} gutterBottom>
            <strong>Qu’est-ce qu’un cookie?</strong>
          </Typography>
          <Typography gutterBottom>
            Un cookie est un fichier de petite taille, qui ne permet pas l’identification
            de l’utilisateur, mais qui enregistre des informations relatives à la
            navigation d’un ordinateur sur un site. Les données ainsi obtenues visent
            à faciliter la navigation ultérieure sur le site, et ont également vocation
            à permettre diverses mesures de fréquentation.
          </Typography>
          <Typography gutterBottom>
            Le refus d’installation d’un cookie peut entraîner l’impossibilité
            d’accéder à certains services.
          </Typography>
          <Typography gutterBottom>
            L’utilisateur peut toutefois configurer son ordinateur de la manière
            suivante, pour refuser l’installation des cookies:
          </Typography>

          <ul className={classes.ul}>
            <li>
              <strong>Sous Internet Explorer: </strong>
              onglet outil (pictogramme en forme de rouage
              en haut à droite) / options internet. Cliquer sur Confidentialité et
              choisissez Bloquer tous les cookies. Valider sur Ok.
            </li>
            <li>
              <strong>Sous Firefox: </strong>
              en haut de la fenêtre du navigateur, cliquer sur
              le bouton Firefox, puis aller dans l’onglet Options. Cliquer sur
              l’onglet Vie privée. Paramétrer les Règles de conservation sur: utiliser
              les paramètres personnalisés pour l’historique. Enfin décocher la
              case pour désactiver les cookies.
            </li>
            <li>
              <strong>Sous Safari: </strong>
              Cliquer en haut à droite du navigateur sur le pictogramme
              de menu (symbolisé par un rouage). Sélectionner Paramètres. Cliquer
              sur afficher les paramètres avancés. Dans la section « Confidentialité »,
              cliquer sur Paramètres de contenu. Dans la section « Cookies », il est
              possible de bloquer les cookies.
            </li>
            <li>
              <strong>Sous Chrome: </strong>
              Cliquer en haut à droite du navigateur sur le pictogramme de
              menu (symbolisé par trois lignes horizontales). Sélectionner
              Paramètres. Cliquer sur afficher les paramètres avancés. Dans la
              section « Confidentialité », cliquer sur préférences. Dans
              l’onglet « Confidentialité », il est possible de bloquer les cookies.
            </li>
          </ul>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>9. Droit applicable et attribution de juridiction</Typography>
          <Typography gutterBottom>
            Tout litige en relation avec l’utilisation du site
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            est soumis au droit français. Il est fait attribution exclusive de
            juridiction aux tribunaux compétents de Paris.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>10. Les principales lois concernées</Typography>
          <Typography gutterBottom>
            Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la
            loi n° 2004-801 du 6 août 2004 relative à l’informatique, aux
            fichiers et aux libertés. Loi n° 2004-575 du 21 juin 2004 pour
            la confiance dans l’économie numérique.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>11. Lexique</Typography>
          <Typography gutterBottom>
            <strong>Utilisateur: </strong>
            Internaute se connectant, utilisant le site susnommé.
            <br />
            <strong>Informations personnelles: </strong>
            « les informations qui permettent, sous quelque forme que ce soit,
            directement ou non, l’identification des personnes physiques auxquelles
            elles s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978)
          </Typography>
        </Box>

      </Container>
    </PublicLayout>
  );
};

export default TermsConditionsView;
