import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    padding: '20px 24px',
    border: '0.5px solid #fff',
    marginTop: -1,
  },
  date: {
    marginRight: theme.spacing(2),
    color: '#e5bba7',
    fontSize: 13,
  },
  message: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: '#fff',
  },
}));

const NotificationItem = ({ date, message }) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Typography className={classes.date}>{moment(date).format('DD/MM')}</Typography>
      <Typography className={classes.message}>{message}</Typography>
    </div>
  );
};

NotificationItem.propTypes = {
  date: PropTypes.string,
  message: PropTypes.string,
};

export default NotificationItem;
