import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    height: '100vh',
  },
  topSection: {
    background: '#fff',
  },
  logoContainer: {
    width: 128,
    cursor: 'pointer',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    fontSize: 40,
    lineHeight: 1.05,
    fontFamily: 'Anke',
  },
  bottomSection: {
    background: '#fff',
  },
  bottomContainer: {
    position: 'relative',
    top: -71,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  }
}));

const PublicLayout = ({
  title,
  children
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        <Container maxWidth="md">
          <Box px={2}>
            <div className={classes.logoContainer} onClick={() => navigate('/login')}>
              <svg enableBackground="new 0 0 200 200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <g fill="#ce9578">
                  <path d="m41.5 88.3v12.4l3.7 3.3h-12.7l3.4-3.3v-24.2l-3.4-1.6 9-6v15.7c1.5-2.2 3.7-3.5 7-3.5 4.4 0 7.6 2.8 7.6 8.1v11.5l3.7 3.3h-12.7l3.4-3.3v-10.1c0-2.5-.1-3.6-.8-4.6s-1.8-1.5-3.5-1.5c-3.4 0-4.4 2.5-4.7 3.8" />
                  <path d="m60.6 93.3c0-6.6 4.4-12.1 11.3-12.1 3.9 0 9.3 1.9 9.7 8.4v.4.5 1.4h-15.7c.2 4.7 3.1 7.9 7.8 7.9 3.3 0 6-1.4 7.8-4.4 0 5.3-5 9.1-9.9 9.1-7.3 0-11-5.5-11-11.2m5.3-3.7h9.8c0-3.7-1.5-6.2-4.5-6.2-2.6-.1-5.3 2.2-5.3 6.2" />
                  <path d="m83.5 93.5c0-7.5 5.1-12.4 12.2-12.4 4.4 0 7.9 2.8 7.9 6.6 0 1.9-1.2 3.6-3.4 3.6-1.7 0-3.3-1.1-3.3-3.4 0-1.9 1.1-4.5-1.7-4.5-3.5 0-6.4 3.1-6.4 7.6 0 5.7 3.3 8.8 7.8 8.8 2.9 0 6.2-1.3 7.7-4.4 0 5.3-5 9.1-9.6 9.1-6.7 0-11.2-4.7-11.2-11" />
                  <path d="m123.3 97.8c-.2 4.6-3.9 6.7-7.5 6.7-5.2 0-7.8-3.3-7.8-8.6v-11.3h-3.3v-3h2.3l4.6-8.9h1.8v8.9h7.9v3h-7.8v10.5c0 3.1.3 5.7 4.4 5.7 1.9 0 3.8-1 5.4-3" />
                  <path d="m134.9 104.5c-7.1-.1-11.8-4.4-11.8-11.6 0-7 4.8-11.8 11.8-11.8 7.4 0 11.8 5.1 11.8 11.7 0 7.3-4.7 11.6-11.8 11.7m-5.3-11.7c0 5 1.1 9.4 5.3 9.4 4.1 0 5.3-4.4 5.3-9.4s-1.2-9.5-5.3-9.5c-4.2 0-5.3 4.5-5.3 9.5" />
                  <path d="m148.3 104h12.6l-3.6-3.3v-8.9c0-.6.1-1.1.2-1.7l-.3-3.7-1.2-6-4.2 2.8v.1l-3.4 2.2 3.4 2.1v13z" />
                  <path d="m166.6 84.8c0 2.2-1.6 3.8-3.3 3.8-1.8 0-3.3-1.2-3.5-3.3-1.4 1.4-2.1 3-2.4 4.9l-.3-3.7c1.1-3.1 3.3-5.4 6-5.4 1.6 0 3.5 1.2 3.5 3.7" />
                  <path d="m74.2 116.7c0-2.4 1.9-4.3 4.3-4.3 1.1 0 2.2.4 2.9 1.1l-.7.8c-.6-.5-1.4-.8-2.3-.8-1.8 0-3.2 1.4-3.2 3.3s1.4 3.3 3.2 3.3c.7 0 1.4-.2 1.9-.5v-2.4h-2.3l.5-1h3v4c-.8.6-1.9.9-3 .9-2.4-.1-4.3-2-4.3-4.4" />
                  <path d="m87.7 116.3c1.1 0 1.7-.5 1.7-1.4s-.6-1.4-1.7-1.4h-1.4v2.8zm-.4.9h-.9v3.7h-1.1v-8.4h2.5c1.6 0 2.7.9 2.7 2.4 0 1.2-.8 2.1-2 2.3l2.9 3.7h-1.3z" />
                  <path d="m100.9 116.7c0-1.8-1.4-3.3-3.2-3.3s-3.2 1.4-3.2 3.3 1.4 3.3 3.2 3.3 3.2-1.5 3.2-3.3m-7.5 0c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3" />
                  <path d="m112.3 116.7c0-1.8-1.4-3.3-3.2-3.3s-3.2 1.4-3.2 3.3 1.4 3.3 3.2 3.3 3.2-1.5 3.2-3.3m-7.5 0c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3" />
                  <path d="m116.8 112.4 4.1 4.5 4.1-4.5v8.5h-1.1v-5.8l-3 3.3-3.1-3.3v5.8h-1z" />
                </g>
              </svg>
            </div>
            <Typography component="h1" className={classes.title} gutterBottom>
              {title}
            </Typography>
          </Box>
          <Box pb={10} />
        </Container>
      </div>
      <div className={classes.bottomSection}>
        <Container maxWidth="md" className={classes.bottomContainer}>
          {children}
        </Container>
      </div>
    </div>
  );
};

PublicLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default PublicLayout;
