import { useEffect, useState } from 'react';
import spotService from '../services/spotService';

const useAllRecipients = () => {
  const [allRecipients, setAllRecipients] = useState([]);
  const [allRecipientsError, setAllRecipientsError] = useState('');
  const [isAllRecipientsLoading, setIsAllRecipientsLoading] = useState(false);

  const fetchAllRecipients = async () => {
    setIsAllRecipientsLoading(true);
    const res = await spotService.getAllRecipients();
    if (!res.ok) {
      setIsAllRecipientsLoading(false);
      return setAllRecipientsError("Erreur lors de l'obtention des utilisateurs");
    }

    setAllRecipients(res.data.data);
    setIsAllRecipientsLoading(false);
  };

  useEffect(() => {
    fetchAllRecipients();
  }, []);

  return {
    allRecipients,
    allRecipientsError,
    isAllRecipientsLoading,
  };
};

export default useAllRecipients;
