import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../components/Logo';
import { logout } from '../redux/reducers/authReducer';
import Loading from '../components/Loading';
import settings from '../config/settings';

const useStyles = makeStyles((theme) => ({
  logoutBtn: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    fontSize: 38,
    lineHeight: 1.05,
    fontFamily: 'Anke',
  },
  propertyItemContainer: {
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  propertyItemImg: {
    display: 'block',
    width: '100%',
    height: 300,
    objectFit: 'cover',
    backgroundColor: '#E38C62',
  },
  propertyItemOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(199,117,76,.24)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  propertyItemText: {
    padding: theme.spacing(4),
    fontFamily: 'Anke',
    fontSize: '1.8rem',
    textTransform: 'uppercase',
    lineHeight: 1.25,
    color: '#fff',
  },
  dialogBgImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#E38C62',
  },
  dialogOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(199,117,76,.28)',
    minHeight: 600,
    padding: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    }
  },
  dialogLogo: {
    width: 34,
  },
  dialogTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    fontFamily: 'Anke',
    fontSize: '2rem',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));

const PropertyItem = ({ property }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = () => {
    setIsDialogOpen(true);

    setTimeout(() => {
      setIsDialogOpen(false);
      window.scrollTo(0, 0);
      navigate(`${property.id.toString()}-${property.hosting_id.toString()}`);
    }, 1500);
  };

  return (
    <>
      <div onClick={handleClick} className={classes.propertyItemContainer}>
        {property.image_backgound ? (
          <img className={classes.propertyItemImg} src={settings.domain + property.image_backgound} alt="" />
        ) : (
          <div className={classes.propertyItemImg} />
        )}
        <div className={classes.propertyItemOverlay}>
          <Typography className={classes.propertyItemText}>{property.name}</Typography>
        </div>
      </div>
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        {property.image_backgound ? (
          <img className={classes.dialogBgImg} src={settings.domain + property.image_backgound} alt="" />
        ) : (
          <div className={classes.dialogBgImg} />
        )}
        <div className={classes.dialogOverlay}>
          <div className={classes.dialogContainer}>
            <div>
              <Logo className={classes.dialogLogo} light />
              <Typography component="h1" className={classes.dialogTitle}>{`${property.adresses} ${property.zipcode} ${property.city}`}</Typography>
              {/* <Typography>n° étage / appartement</Typography> */}
            </div>
            {/* <img alt="Fulton Blanc RVB logo" src="/static/logoFultonBlancRVB.svg" /> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};

PropertyItem.propTypes = {
  property: PropTypes.object.isRequired,
};

const SelectPropertyView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { spots } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  if (!spots) return <Loading />;

  return (
    <Container maxWidth="sm">
      <IconButton
        className={classes.logoutBtn}
        aria-label="logout"
        onClick={handleLogout}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
          <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M13.653 1v0L.368 10.067v0l13.285 8.705v0" />
        </svg>
      </IconButton>
      <Typography component="h1" className={classes.title} gutterBottom>
        Vos propriétés
      </Typography>
      {!spots.length ? (
        <Typography>
          Oups! Il ne semble pas encore y avoir de propriétés associées
          à ce compte!
          <br />
          <a href="mailto:app@hectorgroom.com?subject=Hector Groom - rattachement de ma propriété à mon compte">
            Contactez-nous pour addresser la situation
          </a>
        </Typography>
      ) : spots.map((property) => (
        <PropertyItem key={property.id} property={property} />
      ))}
    </Container>
  );
};

export default SelectPropertyView;
