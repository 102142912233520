import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { useNavigate } from 'react-router-dom';

import useHistory from '../hooks/useHistory';

function BackButton({ backBtnHistory }) {
  const navigate = useNavigate();
  const { previousPath } = useHistory();

  const handleGoBack = () => {
    if (backBtnHistory === 'history') {
      navigate(-1);
    } else {
      navigate(previousPath);
    }
  };

  return (
    <IconButton
      aria-label="go back"
      onClick={handleGoBack}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
        <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M13.653 1v0L.368 10.067v0l13.285 8.705v0" />
      </svg>
    </IconButton>
  );
}

BackButton.propTypes = {
  backBtnHistory: PropTypes.any,
};

export default BackButton;
