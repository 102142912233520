import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
  },
  avatar: {
    borderRadius: 50,
  },
  content: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 0,
  },
  userName: {
    // fontWeight: 'bold',
  },
  title: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  excerpt: {
    fontSize: 11,
    color: '#ce9578',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 13,
    color: '#c7754c',
  },
  unreadMessageIndicator: {
    background: '#ff6e6e',
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  }
}));

const Message = ({
  currentUserId, message, deleteMessageById, swipe
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const userToTalk = message.users.find((user) => user.id !== currentUserId);

  if (!userToTalk) {
    // deleteMessageById(message.topic);
    return null;
  }

  // only navigate if there is no swipe progress
  const handleClick = (id) => {
    if (swipe === 0) navigate(`view/${id}`);
  };

  return (
    <div className={classes.root} onClick={() => handleClick(message.topic)}>
      <Avatar className={classes.avatar}>{`${userToTalk.firstname.charAt(0)}${userToTalk.lastname.charAt(0)}`}</Avatar>
      <div className={classes.content}>
        <Typography className={classes.userName} style={message.conversation.view === 0 ? { fontWeight: 'bold' } : {}}>
          {`${userToTalk.firstname} ${userToTalk.lastname}`}
        </Typography>
        <Typography className={classes.title} style={message.conversation.view === 0 ? { fontWeight: 'bold' } : {}}>{message.conversation.subject}</Typography>
        <Typography className={classes.excerpt}>
          {message.conversation.content.slice(0, 60)}
        </Typography>
      </div>
      {message.conversation.view === 0 && (
        <span className={classes.unreadMessageIndicator} />
      )}
      <Typography className={classes.date}>{moment(message.conversation.created).format('DD/MM')}</Typography>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  currentUserId: PropTypes.any,
  swipe: PropTypes.node,
  deleteMessageById: PropTypes.func,
};

export default Message;
