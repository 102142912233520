import { create } from 'apisauce';

import authStorage from '../localStorage/auth';
import settings from '../config/settings';

const apiClient = create({
  baseURL: settings.apiUrl,
  headers: {
    Accept: 'application/vnd.api+json',
    mode: 'cors',
  },
});

apiClient.addRequestTransform((request) => {
  const authToken = authStorage.getToken();
  if (!authToken) return;
  request.headers.Authorization = `Bearer ${authToken}`;
});

export default apiClient;
