import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Header from '../components/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#f1f1f1',
    height: '100%',
  },
  topSection: {
    background: '#fff',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'flex-end',
    '& img': {
      width: 24,
      marginRight: theme.spacing(1),
    },
    '& p': {
      color: '#ce9578',
    }
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    fontSize: 40,
    lineHeight: 1.05,
    fontFamily: 'Anke',
  },
  bottomSection: {
    background: '#f1f1f1',
  },
  bottomContainer: {
    position: 'relative',
    top: -71,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  }
}));

const SecondaryLayout = ({
  breadcrumbTitle,
  breadcrumbIcon,
  title,
  backBtnHistory,
  children
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        <Container maxWidth="md">
          <Header light backBtnHistory={backBtnHistory} />
          <Box px={2}>
            {(breadcrumbTitle && breadcrumbIcon) && (
              <div className={classes.breadcrumbs}>
                {breadcrumbIcon}
                <Typography>{breadcrumbTitle}</Typography>
              </div>
            )}
            <Typography component="h1" className={classes.title} gutterBottom>
              {title}
            </Typography>
          </Box>
          <Box pb={12} />
        </Container>
      </div>
      <div className={classes.bottomSection}>
        <Container maxWidth="md" className={classes.bottomContainer}>
          {children}
        </Container>
      </div>
    </div>
  );
};

SecondaryLayout.propTypes = {
  breadcrumbTitle: PropTypes.string,
  breadcrumbIcon: PropTypes.object,
  title: PropTypes.string,
  backBtnHistory: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default SecondaryLayout;
