import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(3)}px 28px`,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    }
  },
  link: {
    color: '#fff',
    border: '1px solid #fff',
    padding: theme.spacing(2),
    flex: 1,
    marginLeft: theme.spacing(2),
  }
}));

const Legal = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
          <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
        </svg>
        <a className={classes.link} href="/mentions-legales" target="_blank">Mentions Légales</a>
      </div>
      <div className={classes.item}>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
          <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
        </svg>
        <a className={classes.link} href="/politique-de-confidentialite" target="_blank">Politique de Confidentialité</a>
      </div>
    </div>
  );
};

export default Legal;
