import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GestionnairesItem from './GestionnairesItem';
import useGestionnaires from '../../hooks/useGestionnaires';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(3)}px 28px`,
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& p': {
      marginLeft: theme.spacing(2),
      fontWeight: 600,
      fontSize: 18,
    }
  },
}));

const Gestionnaires = () => {
  const classes = useStyles();
  const { propertyGestionnaires, billingGestionnaires, error } = useGestionnaires();

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <div className={classes.root}>
      {error && (
        <Typography>An error occurred while retriving the data</Typography>
      )}
      {(!propertyGestionnaires.length && !billingGestionnaires.length) && (
        <Typography>
          Vous êtes le gestionnaire de cette propriété
        </Typography>
      )}
      {propertyGestionnaires.length ? (
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
              <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
            </svg>
            <Typography>Immeuble</Typography>
          </div>
          {propertyGestionnaires.map((item, index) => (
            <GestionnairesItem
              key={`${item.firstname}-${index}`} /* eslint-disable-line */
              id={item.id}
              firstname={item.firstname}
              lastname={item.lastname}
              phone={item.phone}
            />
          ))}
        </div>
      ) : null}

      {billingGestionnaires.length ? (
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
              <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
            </svg>
            <Typography>Facturation</Typography>
          </div>
          {billingGestionnaires.map((item, index) => (
            <GestionnairesItem
              key={`${item.firstname}-${index}`} /* eslint-disable-line */
              id={item.id}
              firstname={item.firstname}
              lastname={item.lastname}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Gestionnaires;
