import React from 'react';
import SecondaryLayout from '../layouts/SecondaryLayout';
import Accordion from '../components/Accordion';

import CodeImmeuble from '../components/essentiels/CodeImmeuble';
import CodeWifi from '../components/essentiels/CodeWifi';
import CodeTV from '../components/essentiels/CodeTV';
import ImmeubleInfo from '../components/essentiels/ImmeubleInfo';
import MesDocuments from '../components/essentiels/MesDocuments';
// import Notifications from '../components/essentiels/Notifications';
import Gestionnaire from '../components/essentiels/Gestionnaires';

/*
{
  title: 'Notifications',
  component: <Notifications />
},
{
  title: 'Immeuble Info',
  component: <ImmeubleInfo />
}

*/

const essentielsItems = [
  {
    title: 'Codes Immeuble',
    component: <CodeImmeuble />
  },
  {
    title: 'Wifi',
    component: <CodeWifi />
  },
  {
    title: 'ABONNEMENT TV',
    component: <CodeTV />
  },
  {
    title: 'Mes documents',
    component: <MesDocuments />
  },
  {
    title: 'Gestionnaire',
    component: <Gestionnaire />
  },
];

const EssentialsView = () => {
  return (
    <SecondaryLayout
      breadcrumbTitle="Les essentiels"
      breadcrumbIcon={<img src="/static/icons/keyIcon.svg" alt="" />}
      title="Toutes les infos utiles, au même endroit"
    >
      <Accordion items={essentielsItems} />
    </SecondaryLayout>
  );
};

export default EssentialsView;
