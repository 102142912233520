import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Typography } from '@material-ui/core';

import Accordion from './Accordion';
import MesDocuments from './essentiels/MesDocuments';
import Legal from './essentiels/Legal';
import Gestionnaire from './essentiels/Gestionnaires';
import { logout } from '../redux/reducers/authReducer';
import settings from '../config/settings';
import { toggleUserDrawer } from '../redux/reducers/uiReducer';

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  drawerPaper: {
    width: '100%',
    background: '#f1f1f1',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  userContainer: {
    display: 'flex',
    minHeight: 280,
  },
  backBtn: {
    position: 'absolute',
    top: 8,
    right: 16,
  },
  user: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    '& p': {
      fontSize: 20,
    }
  },
  avatar: {
    width: 108,
    height: 108,
    marginBottom: theme.spacing(2),
    border: '1px solid #fff',
  },
}));

/*
  {
    title: 'Aide',
    component: <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  },
*/

const DrawerItems = [
  {
    title: 'Contact Gestionnaire',
    component: <Gestionnaire />
  },
  {
    title: 'Mes documents',
    component: <MesDocuments />
  },
  {
    title: 'Légale',
    component: <Legal />
  },
  {
    title: 'Logout',
  },
];

const UserDrawer = ({ isOpen, toggleDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const currentProperty = useSelector((state) => state.auth.spots.find(
    (property) => {
      return property.id === Number(propertyId);
    }
  ));

  const handleLogout = () => {
    dispatch(toggleUserDrawer());
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  return (
    <SwipeableDrawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      open={isOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Box className={classes.userContainer} style={currentProperty.image_profil ? { background: `url("${settings.domain + currentProperty.image_profil}")` } : { backgroundColor: '#c7754c' }}>
        <IconButton className={classes.backBtn} color="inherit" aria-label="go back" onClick={toggleDrawer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <path fill="none" stroke="#ffffff" strokeMiterlimit="20" d="M0 0l14 14" />
            <path fill="none" stroke="#ffffff" strokeMiterlimit="20" d="M0 14L14 0" />
          </svg>
        </IconButton>
        <Box className={classes.user}>
          <Box align="center">
            {/*
              <Avatar alt="user avatar" src={user?.avatar} className={classes.avatar} />
            */}
            <Typography>{`${user?.firstname} ${user?.lastname}`}</Typography>
          </Box>
        </Box>
      </Box>
      <Accordion items={DrawerItems} logout={handleLogout} />
    </SwipeableDrawer>
  );
};

UserDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default UserDrawer;
