import menusService from '../../services/menusService';
import spotService from '../../services/spotService';

export const toggleNotificationsDrawer = () => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_NOTIFICATIONS_DRAWER',
    });
  };
};

export const toggleUserDrawer = () => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_USER_DRAWER',
    });
  };
};

export const getMenus = (spotId) => {
  return async (dispatch) => {
    const result = await menusService.getMenus(spotId);
    if (!result.ok) {
      dispatch({
        type: 'ERROR_GET_MENUS',
        data: 'An error occurred while retriving the menus.',
      });
      return;
    }

    const formatedMenus = result.data.data
      .filter((item) => item.attributes.slugFr !== 'Les-essentiels') // remove Essential item from backend
      .filter((item) => item.attributes.slugFr) // remove items with empty slugs
      .map((item) => {
        return {
          id: item.id,
          name: item.attributes.nameFr,
          slug: item.attributes.slugFr,
          icon: item.attributes.icon,
          pageTitle: item.attributes.labelFr,
        };
      });
    dispatch({
      type: 'GET_MENUS',
      data: formatedMenus,
    });
  };
};

export const getHostingInfo = (hostingId) => {
  return async (dispatch) => {
    const result = await spotService.getHostingInfo(hostingId);
    if (!result.ok) {
      return; // #TODO error handling
    }
    dispatch({
      type: 'GET_HOSTING_INFO',
      data: result.data.data.attributes,
    });
  };
};

const initialState = {
  isNotificationsDrawerOpen: false,
  isUserDrawerOpen: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_NOTIFICATIONS_DRAWER':
      return {
        ...state,
        isNotificationsDrawerOpen: !state.isNotificationsDrawerOpen
      };
    case 'TOGGLE_USER_DRAWER':
      return {
        ...state,
        isUserDrawerOpen: !state.isUserDrawerOpen
      };
    case 'GET_MENUS':
      return {
        ...state,
        menus: action.data,
        menus_error: null,
      };
    case 'ERROR_GET_MENUS':
      return {
        ...state,
        menus_error: action.data
      };
    case 'GET_HOSTING_INFO':
      return {
        ...state,
        hostingInfo: action.data
      };
    default:
      return state;
  }
};

export default userReducer;
