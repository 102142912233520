import jwtDecode from 'jwt-decode';

const key = 'authToken';

const storeToken = (authToken) => {
  localStorage.setItem(key, authToken);
};

const getToken = () => {
  return localStorage.getItem(key);
};

// user id must be encrypted in backend when signing the token
// example bellow
/*
const objectToBeEncrypted = {
  id: user.id,
}
const token = jwt.sign(objectToBeEncrypted, process.env.SECRET)
*/

const getUserFromToken = () => {
  const token = getToken();
  return (token) ? jwtDecode(token) : null;
};

const removeToken = () => {
  localStorage.removeItem(key);
};

export default {
  getToken,
  getUserFromToken,
  storeToken,
  removeToken,
};
