import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import PublicLayout from '../layouts/PublicLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    '& p.MuiTypography-gutterBottom': {
      marginBottom: '1rem',
    }
  },
  link: {
    color: '#c7754c'
  },
  title: {
    fontFamily: 'Anke',
  },
  ul: {
    marginLeft: 32,
    '& > li': {
      marginBottom: '0.75rem',
    }
  }
}));

const PrivacyPolicyView = () => {
  const classes = useStyles();
  return (
    <PublicLayout
      title="Politique de Confidentialité"
    >
      <Container className={classes.container} maxWidth="md">
        <Box>
          <Typography className={classes.title} variant="h2" gutterBottom>1. Introduction</Typography>
          <Typography gutterBottom>
            Notre site web,
            &nbsp;
            <a className={classes.link} href="https://hectorgroom.com/">https://hectorgroom.com/</a>
            &nbsp;
            (ci-après: «le site web») utilise des cookies et autres technologies
            liées (par simplification, toutes ces technologies sont désignées par
            le terme «cookies»). Des cookies sont également placés par des tierces
            parties que nous avons engagées. Dans le document ci-dessous, nous vous
            informons de l’utilisation des cookies sur notre site web.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>2. Que sont les cookies?</Typography>
          <Typography gutterBottom>
            Un cookie est un petit fichier simple envoyé avec les pages de ce site
            web et stocké par votre navigateur sur le disque dur de votre ordinateur
            ou d’un autre appareil. Les informations qui y sont stockées peuvent être
            renvoyées à nos serveurs ou aux serveurs des tierces parties concernées
            lors d’une visite ultérieure.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>3. Que sont les scripts?</Typography>
          <Typography gutterBottom>
            Un script est un élément de code utilisé pour que notre site web
            fonctionne correctement et de manière interactive. Ce code est exécuté
            sur notre serveur ou sur votre appareil.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>4. Qu’est-ce qu’une balise invisible?</Typography>
          <Typography gutterBottom>
            Une balise invisible (ou balise web) est un petit morceau de texte ou
            d’image invisible sur un site web, utilisé pour suivre le trafic sur
            un site web. Pour ce faire, diverses données vous concernant sont
            stockées à l’aide de balises invisibles.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2">5. Cookies</Typography>
          <Typography className={classes.title} variant="h3" style={{ marginTop: 48 }} gutterBottom>5.1 Cookies techniques ou fonctionnels</Typography>
          <Typography gutterBottom>
            Certains cookies assurent le fonctionnement correct de certaines
            parties du site web et la prise en compte de vos préférences en
            tant qu’utilisateur. En plaçant des cookies fonctionnels, nous
            vous facilitons la visite de notre site web. Ainsi, vous n’avez
            pas besoin de saisir à plusieurs reprises les mêmes informations
            lors de la visite de notre site web et, par exemple, les éléments
            restent dans votre panier jusqu’à votre paiement. Nous pouvons placer
            ces cookies sans votre consentement.
          </Typography>

          <Typography className={classes.title} variant="h3" style={{ marginTop: 48 }} gutterBottom>5.2 Cookies analytiques</Typography>
          <Typography gutterBottom>
            Les statistiques sont suivies de façon anonyme, aucune permission
            n’est donc demandée pour placer des cookies analytiques.
          </Typography>

          <Typography className={classes.title} variant="h3" style={{ marginTop: 48 }} gutterBottom>5.3 Boutons de réseaux sociaux</Typography>
          <Typography gutterBottom>
            Nous avons inclus sur notre site web des boutons pour que les Twitter
            et LinkedIn puissent promouvoir des pages web (par ex. «J’aime»,
            «Enregistrer») ou partager (par ex. «Tweeter») sur des réseaux
            sociaux tels que Twitter et LinkedIn. Ces boutons fonctionnent avec
            des morceaux de code provenant des Twitter et LinkedIn eux-mêmes. Ce
            code place des cookies. Ces boutons de réseaux sociaux peuvent également
            stocker et traiter certaines informations afin de vous montrer des publicités
            personnalisées.
          </Typography>
          <Typography gutterBottom>
            Veuillez lire la déclaration de confidentialité de ces réseaux sociaux
            (qui peut être modifiée régulièrement) afin de savoir ce qu’ils font de
            vos données (personnelles) traitées en utilisant ces cookies. Les données
            récupérées sont anonymisées autant que possible. Twitter et LinkedIn se
            trouvent aux États-Unis.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>6. Cookies placés</Typography>
          <ul className={classes.ul}>
            <li>
              <strong>Admin</strong>
            </li>
            <li>
              <strong>Google Analytics</strong>
            </li>
            <li>
              <strong>Google Fonts</strong>
            </li>
            <li>
              <strong>Twitter</strong>
            </li>
            <li>
              <strong>LinkedIn</strong>
            </li>
            <li>
              <strong>Divers</strong>
            </li>
          </ul>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>7. Consentement</Typography>
          <Typography gutterBottom>
            Lorsque vous visitez notre site web pour la première fois, nous vous
            montrerons une fenêtre contextuelle avec une explication sur les cookies.
            Dès que vous cliquez sur « Enregistrer les préférences » vous nous autorisez
            à utiliser les catégories de cookies et d’extensions que vous avez sélectionnés
            dans la fenêtre contextuelle, comme décrit dans la présente politique
            de cookies. Vous pouvez désactiver l’utilisation des cookies via votre
            navigateur, mais veuillez noter que notre site web pourrait ne plus
            fonctionner correctement.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>8. Vos droits concernant les données personnelles</Typography>
          <Typography gutterBottom>
            Vous avez les droits suivants concernant vos données personnelles:
          </Typography>
          <ul className={classes.ul}>
            <li>
              Vous avez le droit de savoir pourquoi vos données personnelles
              sont nécessaires, ce qui leur arrivera et combien de temps elles
              seront conservées.
            </li>
            <li>
              Droit d’accès: vous avez le droit d’accéder à vos données
              personnelles que nous connaissons.
            </li>
            <li>
              Droit de rectification : vous avez le droit à tout moment de
              compléter, corriger, faire supprimer ou bloquer vos données personnelles.
            </li>
            <li>
              Si vous nous donnez votre consentement pour le traitement de vos données,
              vous avez le droit de révoquer ce consentement et de faire supprimer vos
              données personnelles.
              <strong>
                Veuillez-noter que si vous nous demander de supprimer vos données
                personnelles nous devrons supprimer votre compte utilisateur sur
                HectorGroom et vous n’aurez donc plus accès à l’application car
                celle-ci ne peut pas fonctionner sans lister certaines informations
                (nom, prénom, numéro d’appartement, email…).
              </strong>
            </li>
            <li>
              Droit de transférer vos données : vous avez le droit de demander
              toutes vos données personnelles au responsable du traitement et de
              les transférer dans leur intégralité à un autre responsable du traitement.
            </li>
            <li>
              Droit d’opposition : vous pouvez vous opposer au traitement
              de vos données. Nous obtempérerons, à moins que certaines raisons
              ne justifient ce traitement.
            </li>
          </ul>
          <Typography gutterBottom>
            Pour exercer ces droits, veuillez nous contacter sur
            &nbsp;
            <a className={classes.link} href="mailto:info@fulton.fr">info@fulton.fr</a>
            . Sinon
            veuillez-vous référer aux coordonnées au bas de cette politique de cookies.
            Si vous avez une plainte concernant la façon dont nous traitons vos données,
            nous aimerions en être informés, mais vous avez également le droit de déposer
            une plainte auprès de l’autorité de contrôle (l’autorité chargée de la
            protection des données, comme le CEPD).
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>9. Activer/désactiver et supprimer les cookies</Typography>
          <Typography gutterBottom>
            Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement
            ou manuellement les cookies. Vous pouvez également spécifier que certains
            cookies ne peuvent pas être placés. Une autre option consiste à modifier
            les réglages de votre navigateur internet afin que vous receviez un message
            à chaque fois qu’un cookie est placé. Pour plus d’informations sur ces options,
            reportez-vous aux instructions de la section Aide de votre navigateur.
          </Typography>
          <Typography gutterBottom>
            Veuillez noter que notre site web peut ne pas marcher correctement si tous
            les cookies sont désactivés. Si vous supprimez les cookies dans votre
            navigateur, ils seront de nouveau placés après votre consentement lorsque
            vous revisiterez nos sites web.
          </Typography>
        </Box>

        <Box pt={8}>
          <Typography className={classes.title} variant="h2" gutterBottom>10. Coordonnées</Typography>
          <Typography gutterBottom>
            Pour des questions et/ou des commentaires sur notre politique en matière de cookies
            et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes:
          </Typography>
          <address style={{ fontStyle: 'normal' }}>
            <Typography gutterBottom>
              FULTON - 39 avenue George V, 75008 Pari
              <br />
              France
              <br />
              Site web:
              &nbsp;
              <a className={classes.link} href="https://fulton.fr">https://fulton.fr</a>
              <br />
              E-mail:
              &nbsp;
              <a className={classes.link} href="mailto:info@fulton.fr">info@fulton.fr</a>
            </Typography>
          </address>
        </Box>

      </Container>
    </PublicLayout>
  );
};

export default PrivacyPolicyView;
