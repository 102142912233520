import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'url("static/images/loginBackground.jpg") no-repeat right bottom',
    backgroundSize: 'cover',
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(229, 187, 167, 0) 0%, #be7e5e 100%)',
    padding: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: 400,
  },
  logo: {
    width: 125,
  },
  title: {
    fontSize: 20,
    marginBottom: theme.spacing(12),
  }
}));

const AuthLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.gradient}>
        <div className={classes.container}>
          <Logo className={classes.logo} light />
          <Outlet />
          {/* <img alt="Fulton Blanc RVB logo" src="/static/logoFultonBlancRVB.svg" /> */}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
