import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import categoryService from '../../services/categoryService';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    '& h2': {
      fontSize: 20,
      fontWeight: 600,
    }
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

const RatingDialog = ({
  item, isOpen, handleClose, updateRatedItem
}) => {
  const classes = useStyles();

  const [userRating, setUserRating] = React.useState(0);

  const resetRatingAndClose = () => {
    setUserRating(0);
    handleClose();
  };

  const handleSubmitRating = async () => {
    const formData = new FormData();
    formData.append('entity_id', item.id);
    formData.append('entity_name', 'Shops');
    formData.append('score', userRating);

    const response = await categoryService.rateItem(formData);
    // console.log('rate response', response);

    updateRatedItem(item.id, response.data.rate.avg, response.data.rate.nb);
    /*
    setListItems((prev) => prev.map((list) => {
      console.log('prev list', prev);
      console.log('new line', (list.id === item.id) ?
      { ...list, rating: response.data.avg } : list);
      return (list.id === item.id) ? { ...list, rating: response.data.avg } : list;
    }));
    */
    setUserRating(0);
    handleClose();
  };

  if (!item) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={classes.dialogTitle}>{`Évaluer ${item.name}`}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Rating
          name="rating"
          value={userRating}
          onChange={(event, newValue) => {
            setUserRating(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={resetRatingAndClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmitRating} color="primary">
          Voter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RatingDialog.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateRatedItem: PropTypes.func,
};

export default RatingDialog;
