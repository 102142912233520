import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/fr';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { FiDownload, FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { BsFileEarmarkText } from 'react-icons/bs';
import { ImFilePdf, ImFileWord, ImFileExcel } from 'react-icons/im';
import Loading from '../../components/Loading';

import messageService from '../../services/messageService';
import BackButton from '../../components/BackButton';
import settings from '../../config/settings';

import { getUnreadMessagesCount } from '../../redux/reducers/authReducer';
import useHistory from '../../hooks/useHistory';

moment.locale('fr');

const useStyles = makeStyles((theme) => ({
  messageID: {
    padding: `0 ${theme.spacing(4)}px`,
    marginTop: -56,
    fontSize: 13,
  },
  container: {
    background: '#fff',
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
  },
  messageLabel: {
    color: '#c7754c',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  messageRecipient: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      lineHeight: 1.25,
    },
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 50,
    marginRight: theme.spacing(2),
  },
  date: {
    color: '#c7754c',
  },
  messageBody: {
    paddingBottom: theme.spacing(4),
    '& p': {
      marginBottom: theme.spacing(2),
    }
  },
  // ===================
  // ===================
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  headerContainer: {
    padding: theme.spacing(2),
  },
  messagesTopic: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 40,
    lineHeight: 1.05,
    fontFamily: 'Anke',
  },
  messsagesSection: {
    flex: 1,
    backgroundColor: '#F1F1F1',
    overflowY: 'auto',
  },
  messagesContainer: {
    backgroundColor: '#F1F1F1',
  },
  messagesContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
  },
  messagefixTopPadding: {
    flex: '0 0 10px',
  },
  messageItem: {
    padding: theme.spacing(2),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '80%',
  },
  messageDate: {
    opacity: 0.7,
    fontSize: 14,
    textAlign: 'right',
  },
  right: {
    background: '#E38C62',
    color: '#fff',
    alignSelf: 'flex-end',
  },
  left: {
    background: '#fff',
  },
  docThumbnailContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
  },
  bottomContainer: {
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    padding: theme.spacing(1),
  },
  inputContainer: {
    display: 'flex',
  },
  inputMessage: {
    padding: theme.spacing(1),
    border: '1px solid #ddd',
    borderRadius: 4,
  },
  iconButton: {
    background: '#E38C62',
    margin: theme.spacing(1),
    '&:hover': {
      background: '#E38C62',
      opacity: '.8',
    }
  },
  docModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  docModalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#E38C62',
  },
  docModalHeaderIcons: {
    color: '#fff',
  },
  docModalBody: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rotated: {
    transform: 'rotate(45deg)',
  },
  fileInputGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  fileInput: {
    display: 'none',
  },
  fileLabel: {
    position: 'static',
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(2),
    textTransform: 'uppercase',
    color: '#c7754c',
    fontSize: 13,
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& img': {
      height: 24,
      marginBottom: theme.spacing(1),
    },
    '& ~ div.MuiInputBase-root': {
      display: 'block',
    }
  },
  filesPreviewContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
  },
  filePreviewItem: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      width: 75,
      height: 75,
      objectFit: 'cover',
    }
  },
  fileWithouPreview: {
    width: 75,
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
  },
  filePreviewRemoveIcon: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  previewIcon: {
    width: 60,
  }
}));

const suportedFiles = [
  'image/jpeg',
  'image/png',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'video/mp4',
  'video/quicktime',
  'video/webm',
  'video/ogg',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const SingleMessageView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useSelector((state) => state.auth.user?.id);

  const [messages, setMessages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [inputMessage, setInputMessage] = useState('');
  const [userToTalk, setUserToTalk] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [failedToFecth, setFailedToFetch] = useState(false);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);
  const [docInModal, setDocInModal] = useState(null);

  const [fileInput, setFileInput] = useState('');
  const [showFileInputs, setShowFileInputs] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileLimitError, setFileLimitError] = useState(false);
  const [fileEmptyError, setFileEmptyError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const handleFileUpload = (event, type) => {
    event.preventDefault();
    const fileReader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    fileReader.onload = () => {
      if (file.size) {
        setFilesToUpload([...filesToUpload, {
          id: uuidv4(),
          type,
          size: file.size,
          path: fileReader.result
        }]);
      }
    };
    // reading the actual uploaded file
    if (file) {
      if (file.size) {
        fileReader.readAsDataURL(file);
      } else {
        fileReader.abort();
        setFileEmptyError(true);
      }
    }
    setFileInput('');
  };

  const handleDeleteFile = (fileId) => {
    const updatedFiles = filesToUpload.filter((item) => (item.id !== fileId ? item : null));
    setFilesToUpload(updatedFiles);
  };

  const fetchMessage = async () => {
    if (currentUserId) {
      const response = await messageService.getMessagesByTopicId(id);
      if (!response.ok) {
        // not authorized force logout
        if (response.status === 401) {
          navigate(location.pathname);
        }
        return setFailedToFetch(true);
      }
      // console.log('messages in this topic', response.data.data[0]);

      if (!response.data.data[0]) {
        const idx = location.pathname.indexOf('/view');
        const previousPath = location.pathname.slice(0, idx);
        navigate(previousPath);
      }
      const messagesOrderedByDate = [...response.data.data[0].conversation].reverse();
      setUserToTalk(response.data.data[0].users.find((user) => user.id !== currentUserId));
      setMessages(messagesOrderedByDate);
      setTotalMessages(messagesOrderedByDate.length);
      setIsLoading(false);
      setFailedToFetch(false);
    }
  };

  const handleSendMessage = async () => {
    setProgress(0);
    // console.log('running');
    let totalSize = 0;
    filesToUpload.forEach((file) => {
      // console.log('itss', file);
      // eslint-disable-next-line
      totalSize += file.size;
    });
    // console.log('total file size', totalSize);

    if (totalSize > 104857600) {
      // console.log('error files to big');
      setFileLimitError(true);
    } else {
      setFileLimitError(false);
      // console.log('continue...');
      setIsSubmitting(true);

      if (inputMessage.length || filesToUpload.length) {
        // console.log('continue2...');
        // const documents = filesToUpload.map((file) => file.path);
        // console.log('docs', documents);
        const formData = new FormData();
        formData.append('content', inputMessage);
        formData.append('from', currentUserId);
        formData.append('to', userToTalk.id);
        formData.append('topic_id', messages[0].channel_id);
        filesToUpload.forEach((file) => {
          formData.append('document[]', file.path);
        });

        const result = await messageService.sendMessage(
          formData,
          (progressUpload) => setProgress(progressUpload)
        );
        setIsSubmitting(false);
        // console.log('new message', result);
        if (!result.ok) {
          setProgress(0);
          return setErrorOpen(true);
        }
        setProgress(0);
        setInputMessage('');
        setFilesToUpload([]);
        setShowFileInputs(false);
        fetchMessage();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchMessage();
  }, [id, currentUserId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessage();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // read current messages
    if (messages) {
      messages.forEach(async (message) => {
        const formData = new FormData();
        formData.append('message_id', message.id);

        const response = await messageService.readMessage(formData);
        // console.log('read message response', response);
      });
      dispatch(getUnreadMessagesCount());
    }
  }, [totalMessages]);

  const closeError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  const handleDocViewer = (doc) => {
    setDocInModal(doc);
    setIsDocModalOpen(true);
  };

  const handleCloseDocViewer = () => {
    setDocInModal(null);
    setIsDocModalOpen(false);
  };

  const isImage = (filetype) => ['image/jpeg', 'image/png'].includes(filetype);
  const isAudio = (filetype) => ['audio/mpeg', 'audio/ogg', 'audio/wav'].includes(filetype);
  const isVideo = (filetype) => ['video/mp4', 'video/quicktime', 'video/webm', 'video/ogg'].includes(filetype);
  const isPDF = (filetype) => ['application/pdf'].includes(filetype);
  const isMicrosoftDoc = (filetype) => ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(filetype);
  const isWordDoc = (filetype) => ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(filetype);
  const isExcelDoc = (filetype) => ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(filetype);
  const isUnknownFile = (filetype) => !suportedFiles.includes(filetype);

  const documentPath = (file) => settings.documentsUrl + file.doc_dir + file.document;

  if (!didMount) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.headerContainer}>
        <div>
          <BackButton />
        </div>
        <Typography className={classes.messagesTopic}>{messages[0].subject}</Typography>
        <div className={classes.user}>
          <Avatar className={classes.avatar}>{`${userToTalk.firstname.charAt(0)}${userToTalk.lastname.charAt(0)}`}</Avatar>
          <div>
            <Typography>{`${userToTalk?.firstname} ${userToTalk?.lastname}`}</Typography>
          </div>
        </div>
      </Container>
      <div className={classes.messsagesSection}>
        <Container maxWidth="md" className={classes.messagesContainer}>
          <div className={classes.messagesContent}>
            {failedToFecth ? (
              <Typography>
                Une erreur s&apos;est produite lors de la récupération des messages
              </Typography>
            )
              : messages.map((message) => (
                <div key={message.id} className={currentUserId === message.user?.id ? `${classes.messageItem} ${classes.right}` : `${classes.messageItem} ${classes.left}`}>
                  <Typography>{message.content}</Typography>
                  {(message.documents.length > 0) && (
                    <div>
                      {message.documents.map((doc) => (
                        <div
                          className={classes.docThumbnailContainer}
                          key={doc.document}
                        >
                          <div onClick={() => handleDocViewer(doc)}>
                            {isImage(doc.doc_type) && (
                              <img src={documentPath(doc)} alt={doc.document} style={{ maxWidth: '100%' }} />
                            )}
                            {isAudio(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_audio_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_audio_file_orange.svg" alt="" />}
                              </>
                            )}
                            {isVideo(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_orange.svg" alt="" />}
                              </>
                            )}
                            {isPDF(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_pdf_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_pdf_file_orange.svg" alt="" />}
                              </>
                            )}
                            {isWordDoc(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_word_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_word_file_orange.svg" alt="" />}
                              </>
                            )}
                            {isExcelDoc(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_xls_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_xls_file_orange.svg" alt="" />}
                              </>
                            )}
                            {isUnknownFile(doc.doc_type) && (
                              <>
                                {currentUserId === message.user?.id
                                  ? <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_file_white.svg" alt="" />
                                  : <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_file_orange.svg" alt="" />}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <Typography className={classes.messageDate}>
                    {moment(message.modified).fromNow()}
                  </Typography>
                </div>
              ))}
            <div className={classes.messagefixTopPadding} />
          </div>
        </Container>
      </div>
      <div className={classes.bottomContainer}>
        <Container maxWidth="md">
          {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
          <div className={classes.inputContainer}>
            <div>
              <IconButton
                className={classes.iconButton}
                onClick={() => setShowFileInputs((prev) => !prev)}
              >
                <FiPlus style={{ color: '#fff', transition: 'ease-out .25s', }} className={showFileInputs ? classes.rotated : ''} />
              </IconButton>
            </div>
            <InputBase
              fullWidth
              multiline
              rows={1}
              rowsMax={4}
              inputProps={
                { className: classes.inputMessage }
              }
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />
            <div>
              <IconButton className={classes.iconButton} aria-label="send" onClick={handleSendMessage}>
                {isSubmitting ? <CircularProgress size={24} /> : <SendIcon style={{ color: '#fff' }} />}
              </IconButton>
            </div>
          </div>
          {filesToUpload.length > 0 && (
            <div className={classes.filesPreviewContainer}>
              {filesToUpload.map((file) => (
                <div key={file.id} className={classes.filePreviewItem}>
                  {(file.type === 'photo')
                    ? (<img src={file.path} alt="" />)
                    : (
                      <div className={classes.fileWithouPreview}>
                        {file.type === 'video' && (
                          <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_orange.svg" alt="" />
                        )}
                        {file.type === 'audio' && (
                          <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_orange.svg" alt="" />
                        )}
                        {file.type === 'doc' && (
                          <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_file_orange.svg" alt="" />
                        )}
                      </div>
                    )}
                  <svg onClick={() => handleDeleteFile(file.id)} className={classes.filePreviewRemoveIcon} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M0 0l14 14" />
                    <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M0 14L14 0" />
                  </svg>
                </div>
              ))}
            </div>
          )}

          {showFileInputs && (
            <div className={classes.fileInputGroup}>
              <div>
                <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-photo-input">
                  <span>
                    <img src="/static/icons/photoIcon.svg" alt="" />
                    Photos
                  </span>
                </InputLabel>
                <InputBase
                  inputProps={{
                    className: classes.fileInput,
                    accept: 'image/*',
                  }}
                  id="file-photo-input"
                  name="file-photo"
                  type="file"
                  value={fileInput}
                  onChange={(e) => handleFileUpload(e, 'photo')}
                />
              </div>
              <div>
                <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-video-input">
                  <span>
                    <img src="/static/icons/videoIcon.svg" alt="" />
                    Vidéo
                  </span>
                </InputLabel>
                <InputBase
                  inputProps={{
                    className: classes.fileInput,
                    accept: 'video/*',
                  }}
                  id="file-video-input"
                  name="file-video"
                  type="file"
                  value={fileInput}
                  onChange={(e) => handleFileUpload(e, 'video')}
                />
              </div>
              {/*
                <div>
                  <InputLabel
                    className={classes.fileLabel}
                    disableAnimation
                    htmlFor="file-audio-input"
                  >
                    <span>
                      <img src="/static/icons/microphoneIcon.svg" alt="" />
                      Message
                    </span>
                  </InputLabel>
                  <InputBase
                    inputProps={{
                      className: classes.fileInput,
                      accept: 'audio/*',
                    }}
                    id="file-audio-input"
                    name="file-audio"
                    type="file"
                    value={fileInput}
                    onChange={(e) => handleFileUpload(e, 'audio')}
                  />
                </div>
              */}
              <div>
                <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-doc-input">
                  <span>
                    <BsFileEarmarkText style={{ fontSize: 24, marginBottom: 8 }} />
                    Fichier
                  </span>
                </InputLabel>
                <InputBase
                  inputProps={{
                    className: classes.fileInput,
                    accept: '.pdf, .doc, .docx, .xls, .xlsx',
                  }}
                  id="file-doc-input"
                  name="file-doc"
                  type="file"
                  value={fileInput}
                  onChange={(e) => handleFileUpload(e, 'doc')}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={5000} onClose={closeError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={closeError}>
          Une erreur s&apos;est produite pendant l&apos;envoi du message.
        </Alert>
      </Snackbar>
      <Dialog
        open={fileEmptyError}
        onClose={() => setFileEmptyError(false)}
      >
        <DialogTitle>Erreur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Le fichier que vous essayez de télécharger est vide
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileEmptyError(false)} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileLimitError}
        onClose={() => setFileLimitError(false)}
      >
        <DialogTitle id="alert-dialog-title">Erreur</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La taille totale de vos fichiers ne doit pas excéder 100mo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileLimitError(false)} color="primary" autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={isDocModalOpen}
        onClose={handleCloseDocViewer}
      >
        <div className={classes.docModalContainer}>
          {docInModal?.document && (
            <>
              <div className={classes.docModalHeader}>
                <a href={documentPath(docInModal)} download>
                  <IconButton className={classes.docModalHeaderIcons}>
                    <FiDownload />
                  </IconButton>
                </a>
                <IconButton
                  className={classes.docModalHeaderIcons}
                  onClick={handleCloseDocViewer}
                >
                  <MdClose />
                </IconButton>
              </div>
              <div className={classes.docModalBody}>
                {isImage(docInModal.doc_type) && (
                  <img src={documentPath(docInModal)} alt={docInModal.document} style={{ maxWidth: '100%' }} />
                )}
                {isAudio(docInModal.doc_type) && (
                  <div>
                    {/* eslint-disable-next-line */}
                    <audio controls style={{maxWidth: '100%'}}>
                      <source src={documentPath(docInModal)} type={docInModal.doc_type} />
                      Votre application ne supporte pas le format de l&apos;élément vidéo.
                    </audio>
                    <Typography style={{ textAlign: 'center', marginTop: 16 }}>{docInModal.document}</Typography>
                  </div>
                )}
                {isVideo(docInModal.doc_type) && (
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {/* eslint-disable-next-line */}
                    <video controls style={{maxWidth: '100%'}}>
                      <source src={documentPath(docInModal)} type="video/mp4" />
                      Votre application ne supporte pas le format de l&apos;élément vidéo.
                    </video>
                    <Typography style={{ textAlign: 'center', marginTop: 16 }}>{docInModal.document}</Typography>
                  </div>
                )}
                {isPDF(docInModal.doc_type) && (
                  <div style={{ width: '100%', height: '100%' }}>
                    <iframe
                      title={docInModal.document}
                      src={documentPath(docInModal)}
                      style={{ width: '100%', height: '100%' }}
                      frameBorder="0"
                      scrolling="no"
                    >
                      <p>Il semble que votre navigateur Web ne prend pas en charge les iframes.</p>
                    </iframe>
                  </div>
                )}
                {isMicrosoftDoc(docInModal.doc_type) && (
                  <div style={{ width: '100%', height: '100%' }}>
                    <iframe
                      title={docInModal.document}
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${documentPath(docInModal)}`}
                      style={{ width: '100%', height: '100%' }}
                      frameBorder="0"
                      scrolling="no"
                    >
                      <Typography>
                        Il semble que votre navigateur Web ne prend pas en charge les iframes.
                      </Typography>
                    </iframe>
                  </div>
                )}

                {isUnknownFile(docInModal.doc_type) && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_file_orange.svg" alt="" />
                    <Typography style={{ textAlign: 'center', marginTop: 16 }}>{docInModal.document}</Typography>
                    <Typography>Aperçu non disponible</Typography>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default SingleMessageView;
