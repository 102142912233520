import client from './client';

const login = (credentials) => client.post('/users/login', credentials, { headers: { Accept: 'application/json' } });

const syncUser = () => client.get('/users/informations', {}, { headers: { Accept: 'application/json' } });

const register = (data) => client.post('/users/register', data, { headers: { Accept: 'application/json' } });

export default {
  login,
  syncUser,
  register,
};
