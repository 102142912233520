import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MesDocumentsItem from './MesDocumentsItem';
// import useDocuments from '../../hooks/useDocuments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(3)}px 28px`,
  },
}));

const MesDocuments = () => {
  const classes = useStyles();
  const hostingInfo = useSelector((state) => state.ui.hostingInfo);

  return (
    <div className={classes.root}>
      {hostingInfo?.doc1 && <MesDocumentsItem name="BAIL" file={hostingInfo.doc1} />}
      {hostingInfo?.doc2 && <MesDocumentsItem name="POLICE D'ASSURANCE" file={hostingInfo.doc2} />}
      {hostingInfo?.doc3 && <MesDocumentsItem name="NOTICE ÉLECTROMÉNAGER" file={hostingInfo.doc3} />}
    </div>
  );
};

export default MesDocuments;
