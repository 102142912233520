import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toggleUserDrawer } from '../../redux/reducers/uiReducer';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    display: 'flex',
    border: '1px solid #fff',
    '& p': {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderLeft: 'none',
      flex: 1,
      color: '#fff',
      fontSize: 13,
      lineHeight: 1.25,
      '& span': {
        textTransform: 'uppercase',
      }
    },
    '& a': {
      borderLeft: '1px solid #fff',
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    }
  }
}));

const GestionnairesItem = ({
  id, firstname, lastname, phone
}) => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { hostingId } = useParams();
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <Typography>
        <strong>{`${firstname} ${lastname}`}</strong>
      </Typography>
      <Link to={`/app/${propertyId}-${hostingId}/messages/new?name=${firstname}-${lastname}&id=${id}`} onClick={() => dispatch(toggleUserDrawer())}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18">
          <path fill="#fff" d="M1.886 16.22V4.198l13.516 10.673L28.92 4.198V16.22zM28.919 1.08v1.76L15.402 13.514 1.886 2.84V1.08zM.805 0v17.3H30V0z" />
        </svg>
      </Link>
      {phone && (
        <a href={`tel:${phone}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
            <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M13.362 17.872C8.288 17.872.932 10.59.932 5.44c0-2.034 1.272-3.941 3.444-3.941.455 0 2.511 3.51 2.511 3.943 0 .71-2.106 1.744-2.106 2.371 0 1.525 4.686 6.21 6.21 6.21.627 0 1.662-2.106 2.371-2.106.132 0 3.797 1.938 3.91 2.27.776 1.806-2.12 3.685-3.91 3.685z" />
          </svg>
        </a>
      )}
    </div>
  );
};

GestionnairesItem.propTypes = {
  id: PropTypes.any,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
};

export default GestionnairesItem;
