import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import NotificationItem from './NotificationItem';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100vh',
  },
  drawerPaper: {
    width: '100vw',
    height: '100vh',
    background: '#c7754c',
  },
  backBtn: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
  },
  header: {
    padding: `${theme.spacing(8)}px ${theme.spacing(1)}px`,
    color: '#fff',
  },
  title: {
    fontFamily: 'Anke',
    fontSize: 40,
  },
  subtitle: {
    fontSize: 13,
  },
  notificationsContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 -17px',
    },
  }
}));

const NotificationsDrawer = ({ isDrawerOpen, toggleDrawer }) => {
  const classes = useStyles();
  const notifications = useSelector((state) => state.auth.notifications);

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="bottom"
      open={isDrawerOpen}
      onClose={toggleDrawer}
    >
      <Container maxWidth="md">
        <IconButton edge="start" className={classes.backBtn} color="inherit" aria-label="go back" onClick={toggleDrawer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
            <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M13.653 1v0L.368 10.067v0l13.285 8.705v0" />
          </svg>
        </IconButton>
        <div className={classes.header}>
          <Typography component="h3" className={classes.title}>Notifications</Typography>
          <Typography className={classes.subtitle}>ET AUTRES NOUVELLES LOCALES</Typography>
        </div>
        <div className={classes.notificationsContainer}>
          {notifications.map(({ id, date, message }) => (
            <NotificationItem
              key={id}
              date={date}
              message={message}
            />
          ))}
        </div>
      </Container>
    </Drawer>
  );
};

NotificationsDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default NotificationsDrawer;
