import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import spotService from '../services/spotService';

const useGestionnaires = () => {
  const { user } = useSelector((state) => state.auth);
  const [allGestionnaires, setAllGestionnaires] = useState([]);
  const [propertyGestionnaires, setPropertyGestionnaires] = useState([]);
  const [billingGestionnaires, setBillingGestionnaires] = useState([]);
  const [uniqueGestionnaires, setUniqueGestionnaires] = useState([]);
  const [isGestionnairesLoading, setIsGestionnairesLoading] = useState(false);
  const [error, setError] = useState('');

  const { propertyId } = useParams();

  const removeDuplicates = (duplicates) => {
    const uniqueItems = [];
    const flaggedIds = [];
    duplicates.forEach((item) => {
      if (!flaggedIds.some((flag) => flag === item.id)) {
        flaggedIds.push(item.id);
        uniqueItems.push(item);
      }
    });
    return uniqueItems;
  };

  const fetchGestionnaires = async () => {
    setIsGestionnairesLoading(true);
    const res = await spotService.getGestionnaires(propertyId);
    // console.log('res gest', res);
    if (!res.ok) {
      setIsGestionnairesLoading(false);
      return setError("Erreur lors de l'obtention des gestionnaires.");
    }

    setError('');
    const formatResponse = res.data.data.managers.map((manager) => {
      if (manager.role === 2) {
        return {
          ...manager,
          firstname: 'Service',
          lastname: 'Facturation',
        };
      }
      return manager;
    });

    // if gestionnaire is current logged in user remove it
    const removedSelf = formatResponse.filter((gest) => gest.id !== user.id);

    if (!removedSelf.length) {
      return setIsGestionnairesLoading(false);
    }

    const uniques = removeDuplicates(removedSelf);

    setAllGestionnaires(removedSelf);
    setUniqueGestionnaires(uniques);

    const property = removedSelf.filter((manager) => manager.role === 1);
    const billing = removedSelf.filter((manager) => manager.role === 2);

    if (property.length) setPropertyGestionnaires(property);
    if (billing.length) setBillingGestionnaires(billing);
    setIsGestionnairesLoading(false);
  };

  useEffect(() => {
    fetchGestionnaires();
  }, [propertyId]);

  return {
    isGestionnairesLoading,
    allGestionnaires,
    propertyGestionnaires,
    billingGestionnaires,
    uniqueGestionnaires,
    error
  };
};

export default useGestionnaires;
