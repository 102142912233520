import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { BsFileEarmarkText } from 'react-icons/bs';

import messageService from '../services/messageService';
import useHistory from '../hooks/useHistory';

const useStyles = makeStyles((theme) => ({
  inputsGroup: {
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  FormControl: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  inputContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    width: '100%',
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  label: {
    position: 'static',
    transform: 'none',
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: '#c7754c',
    fontSize: 13,
  },
  input: {
    padding: theme.spacing(2),
  },
  charactersLimit: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#c7754c',
    fontSize: 13,
  },
  submitBtn: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    height: 91,
  },

  fileInputGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  fileInput: {
    display: 'none',
  },
  fileLabel: {
    position: 'static',
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(2),
    textTransform: 'uppercase',
    color: '#c7754c',
    fontSize: 13,
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& img': {
      height: 24,
      marginBottom: theme.spacing(1),
    },
    '& ~ div.MuiInputBase-root': {
      display: 'block',
    }
  },
  filesPreviewContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
  },
  filePreviewItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    '& img': {
      height: 75,
      width: 75,
      objectFit: 'cover',
    }
  },
  previewIcon: {
    objectFit: 'contain !important',
  },
  fileWithouPreview: {
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filePreviewRemoveIcon: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  btnLoading: {
    color: '#fff',
  },
  uploadProgress: {
    marginTop: theme.spacing(2),
  }
}));

const useModalStyles = makeStyles((theme) => ({
  root: {
    background: '#7d988a',
    height: '100%',
    width: '100%',
  },
  rootFailed: {
    background: '#c7754c',
    height: '100%',
    width: '100%',
  },
  overlay: {
    minHeight: 600,
    height: '100%',
    width: '100%',
    padding: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    }
  },
  text: {
    marginTop: theme.spacing(2),
    maxWidth: 130,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

const validationSchema = Yup.object().shape({
  subject: Yup.string().max(60).required('Le sujet est obligatoire'),
  comment: Yup.string().max(500).required('Un commentaire est requis'),
});

const ContactForm = ({ gestionnaire }) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const navigate = useNavigate();
  const currentUserId = useSelector((state) => state.auth.user?.id);
  const { previousPath } = useHistory();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [fileInput, setFileInput] = useState('');
  const [isFailedOpen, setIsFailedOpen] = useState(false);
  const [fileLimitError, setFileLimitError] = useState(false);
  const [fileEmptyError, setFileEmptyError] = useState(false);
  const [progress, setProgress] = useState(0);

  const [error, setError] = useState();
  const [files, setFiles] = useState([]);

  const handleSubmit = async ({ subject, comment }, { setSubmitting }) => {
    setProgress(0);
    let totalSize = 0;
    files.forEach((file) => {
      // eslint-disable-next-line
      totalSize += file.size;
    });

    if (totalSize > 104857600) {
      setFileLimitError(true);
    } else {
      setFileLimitError(false);
      const formData = new FormData();
      formData.append('subject', subject);
      formData.append('content', comment);
      formData.append('from', currentUserId);
      formData.append('to', gestionnaire);
      files.forEach((file) => {
        formData.append('document[]', file.path);
      });

      const result = await messageService.sendMessage(
        formData,
        (progressUpload) => setProgress(progressUpload)
      );
      // console.log('result new topic', result);
      if (!result.ok) {
        setProgress(0);
        setSubmitting(false);
        return setIsFailedOpen(true);
      }
      setProgress(0);
      setIsSuccessOpen(true);
    }
  };

  const handleFileUpload = (event, type) => {
    event.preventDefault();
    const fileReader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    fileReader.onload = () => {
      if (file.size) {
        setFiles([...files, {
          id: uuidv4(),
          type,
          size: file.size,
          path: fileReader.result
        }]);
      }
    };
    // reading the actual uploaded file
    if (file) {
      if (file.size) {
        fileReader.readAsDataURL(file);
      } else {
        fileReader.abort();
        setFileEmptyError(true);
      }
    }
    setFileInput('');
  };

  const handleDeleteFile = (id) => {
    const filesWithoutRemovedFile = files.filter((item) => (item.id !== id ? item : null));
    setFiles(filesWithoutRemovedFile);
  };

  const handleGoBack = () => {
    navigate(previousPath);
  };

  useEffect(() => {
    if (isSuccessOpen) {
      setTimeout(() => handleGoBack(), 2000);
    }
  }, [isSuccessOpen]);

  useEffect(() => {
    if (isFailedOpen) {
      setTimeout(() => setIsFailedOpen(false), 2000);
    }
  }, [isFailedOpen]);

  const formik = useFormik({
    initialValues: {
      subject: '',
      comment: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.inputsGroup}>
          <FormControl className={classes.FormControl}>
            <InputLabel className={classes.label} disableAnimation required htmlFor="subject-input">Sujet</InputLabel>
            <div className={classes.inputContainer}>
              <InputBase
                fullWidth
                inputProps={
                  { className: classes.input, maxLength: 60, 'aria-describedby': 'subject-helper-text' }
                }
                id="subject-input"
                name="subject"
                value={formik.values.subject}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.subject && formik.errors.subject)}
              />
              <Typography className={classes.charactersLimit}>
                (
                {60 - formik.values.subject.length}
                )
              </Typography>
            </div>
            {(formik.touched.subject && formik.errors.subject) && (
              <FormHelperText error id="subject-helper-text">{formik.errors.subject}</FormHelperText>
            )}
          </FormControl>

          <FormControl className={classes.FormControl}>
            <InputLabel className={classes.label} disableAnimation required htmlFor="comment-input">Commentaires</InputLabel>
            <div className={classes.inputContainer}>
              <InputBase
                fullWidth
                inputProps={
                  { className: classes.input, maxLength: 500, 'aria-describedby': 'comment-helper-text' }
                }
                id="comment-input"
                name="comment"
                multiline
                rows={6}
                value={formik.values.comment}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.comment && formik.errors.comment)}
              />
              <Typography className={classes.charactersLimit}>
                (
                {500 - formik.values.comment.length}
                )
              </Typography>
            </div>
            {(formik.touched.comment && formik.errors.comment) && (
              <FormHelperText error id="comment-helper-text">{formik.errors.comment}</FormHelperText>
            )}
          </FormControl>
          <InputLabel className={classes.label} disableAnimation htmlFor="attachments-input">ajout de documents</InputLabel>
          <div className={classes.fileInputGroup}>
            <div>
              <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-photo-input">
                <span>
                  <img src="/static/icons/photoIcon.svg" alt="" />
                  Photos
                </span>
              </InputLabel>
              <InputBase
                inputProps={{
                  className: classes.fileInput,
                  accept: 'image/*',
                }}
                id="file-photo-input"
                name="file-photo"
                type="file"
                value={fileInput}
                onChange={(e) => handleFileUpload(e, 'photo')}
              />
            </div>
            <div>
              <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-video-input">
                <span>
                  <img src="/static/icons/videoIcon.svg" alt="" />
                  Vidéo
                </span>
              </InputLabel>
              <InputBase
                inputProps={{
                  className: classes.fileInput,
                  accept: 'video/*',
                }}
                id="file-video-input"
                name="file-video"
                type="file"
                value={fileInput}
                onChange={(e) => handleFileUpload(e, 'video')}
              />
            </div>
            {/*
              <div>
              <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-audio-input">
                <span>
                  <img src="/static/icons/microphoneIcon.svg" alt="" />
                  Message
                </span>
              </InputLabel>
              <InputBase
                inputProps={{
                  className: classes.fileInput,
                  accept: 'audio/*',
                }}
                id="file-audio-input"
                name="file-audio"
                type="file"
                value={fileInput}
                onChange={(e) => handleFileUpload(e, 'audio')}
              />
            </div>
            */}
            <div>
              <InputLabel className={classes.fileLabel} disableAnimation htmlFor="file-doc-input">
                <span>
                  <BsFileEarmarkText style={{ fontSize: 24, marginBottom: 8 }} />
                  Fichier
                </span>
              </InputLabel>
              <InputBase
                inputProps={{
                  className: classes.fileInput,
                  accept: '.pdf, .doc, .docx, .xls, .xlsx',
                }}
                id="file-doc-input"
                name="file-doc"
                type="file"
                value={fileInput}
                onChange={(e) => handleFileUpload(e, 'doc')}
              />
            </div>
          </div>

          <div className={classes.filesPreviewContainer}>
            { files && files.map((file) => (
              <div key={file.id} className={classes.filePreviewItem}>
                {(file.type === 'photo')
                  ? (<img src={file.path} alt="" />)
                  : (
                    <div className={classes.fileWithouPreview}>
                      {file.type === 'video' && (
                        <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_orange.svg" alt="" />
                      )}
                      {file.type === 'audio' && (
                        <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_video_file_orange.svg" alt="" />
                      )}
                      {file.type === 'doc' && (
                        <img className={classes.previewIcon} src="/static/icons/sweetgroom_icon_file_orange.svg" alt="" />
                      )}
                    </div>
                  )}
                <svg onClick={() => handleDeleteFile(file.id)} className={classes.filePreviewRemoveIcon} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                  <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M0 0l14 14" />
                  <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M0 14L14 0" />
                </svg>
              </div>
            ))}
          </div>
        </div>

        {progress > 0 && <LinearProgress className={classes.uploadProgress} variant="determinate" value={progress} />}

        <Button
          className={classes.submitBtn}
          color="primary"
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {formik.isSubmitting ? <CircularProgress className={classes.btnLoading} /> : 'ENVOYER'}
        </Button>
      </form>
      <Dialog
        open={fileEmptyError}
        onClose={() => setFileEmptyError(false)}
      >
        <DialogTitle>Erreur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Le fichier que vous essayez de télécharger est vide
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileEmptyError(false)} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileLimitError}
        onClose={() => setFileLimitError(false)}
      >
        <DialogTitle id="alert-dialog-title">Erreur</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La taille totale de vos fichiers ne doit pas excéder 100mo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileLimitError(false)} color="primary" autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        aria-labelledby="message sent"
      >
        <div className={modalClasses.root} onClick={handleGoBack}>
          <div className={modalClasses.overlay}>
            <div className={modalClasses.container}>
              <div>
                <img alt="" src="/static/icons/messageSentIcon.svg" />
                <Typography
                  className={modalClasses.text}
                >
                  votre message à bien été envoyé
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        fullScreen
        open={isFailedOpen}
        onClose={() => setIsFailedOpen(false)}
        aria-labelledby=""
      >
        <div className={modalClasses.rootFailed} onClick={() => setIsFailedOpen(false)}>
          <div className={modalClasses.overlay}>
            <div className={modalClasses.container}>
              <div>
                <IoIosCloseCircleOutline style={{ fontSize: 35 }} />
                <Typography
                  className={modalClasses.text}
                >
                  Une erreur est survenue! Merci de réessayer
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

ContactForm.propTypes = {
  gestionnaire: PropTypes.any,
};

export default ContactForm;
