import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { FiAlertCircle } from 'react-icons/fi';
import Loading from '../../components/Loading';
import SecondaryLayout from '../../layouts/SecondaryLayout';
import NewMessageTopicForm from '../../components/NewMessageTopicForm';

import useGestionnaires from '../../hooks/useGestionnaires';
import useAllRecipients from '../../hooks/useAllRecipients';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '0 32px',
    marginBottom: 40,
    marginTop: -28,
    '& div.MuiInputBase-root': {
      marginTop: 0,
    },
  },
  label: {
    position: 'static',
    transform: 'none',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    color: '#c7754c',
    fontSize: 13,
  },
  noGestionnaires: {
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d32f2f',
  },
  errorIcon: {
    marginRight: 8,
    fontSize: 24,
    flexShrink: 0,
  }
}));

const useQuery = () => new URLSearchParams(useLocation().search);

const MessageFormView = () => {
  const classes = useStyles();
  const query = useQuery();
  const [nameQuery, setNameQuery] = useState(query.get('name'));
  const [activeGestionnaire, setActiveGestionnaire] = useState(query.get('name') || '');
  const [activeGestId, setActiveGestId] = useState(Number(query.get('id')) || '');
  const [activeRecipient, setActiveRecipient] = useState('');
  const [activeRecipientId, setActiveRecipientId] = useState(null);
  const { allGestionnaires, isGestionnairesLoading, error } = useGestionnaires();
  const { allRecipients, isAllRecipientsLoading, allRecipientsError } = useAllRecipients();

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  useEffect(() => {
    setNameQuery(query.get('name'));
  }, [query]);

  useEffect(() => {
    if (allRecipients.length) {
      setActiveRecipient(`${allRecipients[0].firstname}-${allRecipients[0].lastname}`);
      setActiveRecipientId(allRecipients[0].id);
    }
  }, [allRecipients]);

  useEffect(() => {
    if (allGestionnaires.length && !nameQuery) {
      setActiveGestionnaire(`${allGestionnaires[0].firstname}-${allGestionnaires[0].lastname}`);
      setActiveGestId(allGestionnaires[0].id);
    }
  }, [allGestionnaires]);

  useEffect(() => {
    if (nameQuery) {
      setActiveGestionnaire(query.get('name'));
      setActiveGestId(Number(query.get('id')));
    }
  }, [nameQuery]);

  const handleGestionnaire = (e) => {
    setActiveGestionnaire(e.target.value);

    if (allGestionnaires.length) {
      const gestToSelect = allGestionnaires.find((gest) => {
        const stringPattern = `${gest.firstname}-${gest.lastname}`;
        return stringPattern === e.target.value;
      });
      if (gestToSelect) {
        setActiveGestId(gestToSelect.id);
      }
    }
  };

  const handleRecipient = (e) => {
    setActiveRecipient(e.target.value);

    if (allRecipients.length) {
      const userToSelect = allRecipients.find((recipient) => {
        const stringPattern = `${recipient.firstname}-${recipient.lastname}`;
        return stringPattern === e.target.value;
      });
      if (userToSelect) {
        setActiveRecipientId(userToSelect.id);
      }
    }
  };

  if (!didMount) {
    return null;
  }

  if (isGestionnairesLoading || isAllRecipientsLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <SecondaryLayout
        title="Votre gestionnaire est là pour vous"
      >
        <Typography>{error}</Typography>
      </SecondaryLayout>
    );
  }

  if (allRecipientsError) {
    return (
      <SecondaryLayout
        title="Votre gestionnaire est là pour vous"
      >
        <Typography>{allRecipientsError}</Typography>
      </SecondaryLayout>
    );
  }

  return (
    <SecondaryLayout
      title="Votre gestionnaire est là pour vous"
    >
      {allGestionnaires.length ? (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="gestionnaire-label" className={classes.label} disableAnimation>Gestionnaire</InputLabel>
            <Select
              className={classes.select}
              labelId="gestionnaire-label"
              id="gestionnaire-select"
              value={activeGestionnaire}
              onChange={handleGestionnaire}
            >
              {allGestionnaires.map((item) => (
                <MenuItem key={item.firstname} value={`${item.firstname}-${item.lastname}`}>{`${item.firstname} ${item.lastname}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <NewMessageTopicForm gestionnaire={activeGestId} />
        </>
      ) : (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="allrecipients-label" className={classes.label} disableAnimation>Utilisateurs</InputLabel>
            <Select
              className={classes.select}
              labelId="allrecipients-label"
              id="allrecipients-select"
              value={activeRecipient}
              onChange={handleRecipient}
            >
              {allRecipients.map((item) => (
                <MenuItem key={item.firstname} value={`${item.firstname}-${item.lastname}`}>{`${item.firstname} ${item.lastname}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <NewMessageTopicForm gestionnaire={activeRecipientId} />
        </>
      )}
    </SecondaryLayout>
  );
};

export default MessageFormView;
