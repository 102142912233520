import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Accordion = withStyles({
  root: {
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    marginBottom: 16,
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 100,
    padding: '0 28px',
    '&$expanded': {
      boxShadow: '0 3px 6px rgba(0,0,0,.16)',
      minHeight: 100,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
    '& p': {
      color: '#ce9578',
      fontSize: 20,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    background: '#c7754c',
    color: '#fff',
    padding: 0,
  },
}))(MuiAccordionDetails);

const CustomizedAccordions = ({ items, hostingInfo, logout }) => {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {items.map((item) => (
        <Accordion
          key={item.title}
          square
          expanded={expanded === item.title}
          onChange={handleChange(item.title)}
        >
          <AccordionSummary aria-controls={item.title} onClick={item.title === 'Logout' ? logout : null}>
            <Typography>{item.title}</Typography>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
              <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M.5.5h22" />
            </svg>
          </AccordionSummary>
          {item.component && (
            <AccordionDetails>
              {item.component}
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </div>
  );
};

CustomizedAccordions.propTypes = {
  items: PropTypes.array.isRequired,
  hostingInfo: PropTypes.object,
  logout: PropTypes.func,
};

export default CustomizedAccordions;
