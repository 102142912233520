import client from './client';

const getAllTopics = async () => client.get('/messages', {}, { headers: { Accept: 'application/json' } });

const getUnreadMessagesCount = async () => client.get('/messages/count', {}, { headers: { Accept: 'application/json' } });

const getMessagesByTopicId = async (id) => client.get(`/messages/${id}`, {}, { headers: { Accept: 'application/json' } });

const sendMessage = async (data, onUploadProgress) => client.post('/messages/add', data, {
  headers: { Accept: 'application/json', 'content-type': 'multipart/form-data' },
  onUploadProgress: (progress) => onUploadProgress(
    Math.ceil((progress.loaded / progress.total) * 100)
  )
});

const readMessage = async (data) => client.post('/messages/read', data, { headers: { Accept: 'application/json' } });

const archiveMessage = async (id) => {
  // archive request here
  console.log('archived message with id of', id);
};

const deleteMessage = async (topicId) => client.delete(`/channels/${topicId}`);

export default {
  getAllTopics,
  getUnreadMessagesCount,
  readMessage,
  getMessagesByTopicId,
  sendMessage,
  archiveMessage,
  deleteMessage,
};
