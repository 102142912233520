import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const infoItemStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    padding: '20px 24px',
    border: '0.5px solid #fff2',
    marginTop: -1,
  },
  label: {
    marginRight: theme.spacing(2),
    color: '#e5bba7',
    fontSize: 13,
  },
  value: {
    fontSize: 13,
    color: '#fff',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  bottom: {
    padding: `24px ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
  btn: {
    color: '#fff',
    border: '1px solid #fff',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    borderRadius: 0,
  }
}));

const InfoItem = ({ label, value }) => {
  const classes = infoItemStyles();

  return (
    <div className={classes.row}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
};

InfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

const ImmeubleInfo = () => {
  const classes = useStyles();
  const [formatedInfo, setFormatedInfo] = useState([]);
  const hostingInfo = useSelector((state) => state.ui.hostingInfo);

  useEffect(() => {
    const tempArray = [
      {
        label: 'Porte',
        value: hostingInfo?.door
      },
      {
        label: 'Sol',
        value: hostingInfo?.floor
      },
      {
        label: 'À vendre',
        value: hostingInfo?.forSale === 0 ? 'Non' : 'Oui'
      },
      {
        label: 'Vélo local',
        value: hostingInfo?.localBike === 0 ? 'Non' : 'Oui'
      },
      {
        label: 'Ski local',
        value: hostingInfo?.localSki === 0 ? 'Non' : 'Oui'
      },
      {
        label: 'Appartement Nª',
        value: hostingInfo?.name
      },
      {
        label: 'Parking',
        value: hostingInfo?.parking === 0 ? 'Non' : 'Oui'
      },
      {
        label: 'Pièces',
        value: hostingInfo?.room
      },
    ];

    setFormatedInfo(tempArray);
  }, [hostingInfo]);

  return (
    <div className={classes.root}>
      {formatedInfo.map((item) => (
        <InfoItem
          key={item.label}
          label={item.label}
          value={item.value}
        />
      ))}
    </div>
  );
};

export default ImmeubleInfo;
