import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import HomeMenu from '../components/HomeMenu';
import settings from '../config/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#f1f1f1',
    minHeight: '100%',
  },
  topSection: {
    position: 'relative',
  },
  topSectionBgImg: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#E38C62',
  },
  topSectionOverlay: {
    position: 'relative',
    background: 'rgba(199,117,76,.44)',
    color: '#fff',
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: 40,
    lineHeight: 1.05,
    fontFamily: 'Anke',
  },
  subtitle: {
    width: '70%',
    lineHeight: 1.25,
    // paddingBottom: theme.spacing(12),
  },
  bottomSection: {
    position: 'relative',
    top: -48,
  }
}));

const DashboardView = () => {
  const classes = useStyles();
  const { propertyId } = useParams();
  const currentProperty = useSelector((state) => state.auth.spots.find(
    (property) => {
      return property.id === Number(propertyId);
    }
  ));
  const hostingInfo = useSelector((state) => state.ui.hostingInfo);

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        {
          currentProperty?.image_backgound ? (
            <img
              className={classes.topSectionBgImg}
              src={settings.domain + currentProperty.image_backgound}
              alt={currentProperty?.name}
            />
          ) : (
            <div className={classes.topSectionBgImg} />
          )
        }
        <div className={classes.topSectionOverlay}>
          <Container maxWidth="md">
            <Header />
            <Box p={2}>
              <Typography component="h1" className={classes.title} gutterBottom>
                Bienvenue
                <br />
                chez vous !
              </Typography>
              <Typography className={classes.subtitle}>
                {currentProperty?.adresses}
                <br />
                {`${currentProperty?.zipcode} ${currentProperty?.city}`}
                <br />
                {`${hostingInfo?.floor}e étage / Appt. nº ${hostingInfo?.name}`}
                {/*
                  <br />
                  {`Code Rue ${hostingInfo?.codeStreet}`}
                  <br />
                  {`Code Hall ${hostingInfo?.codeHall}`}
                */}
                <br />
                <br />
              </Typography>
              <Typography className={classes.subtitle}>
                Retrouvez ici toutes les infos importantes,
                les bons plans autour de vous, et gardez du temps, pour ce qui compte vraiment.
              </Typography>
            </Box>
            <Box pb={12} />
          </Container>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <Container maxWidth="md">
          <HomeMenu />
        </Container>
      </div>
    </div>
  );
};

export default DashboardView;
