import React, { useLayoutEffect, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../components/Loading';

import { getUnreadMessagesCount } from '../redux/reducers/authReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const MainLayout = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUnreadMessagesCount());
  }, []);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={classes.root}>
      {!user ? <Loading /> : <Outlet />}
    </div>
  );
};

export default MainLayout;
