import { useEffect, useState } from 'react';
import messageService from '../services/messageService';

const useMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLoadingMessages, setErrorLoadingMessages] = useState(false);

  const archiveMessageById = (id) => {
    setMessages((n) => n.filter((item) => item.id !== id));
    messageService.archiveMessage(id);
  };

  const deleteMessageById = (topicId) => {
    messageService.deleteMessage(topicId);
    setMessages((prev) => prev.filter((item) => item.topic !== topicId));
  };

  useEffect(() => {
    // console.log('current msg', messages);
  }, [messages]);

  const fetchMessages = async () => {
    const response = await messageService.getAllTopics();
    // console.log('list of messages', response);
    if (!response.ok) return setErrorLoadingMessages(true);
    const removedNulls = [];
    if (typeof response.data === 'object') {
      response.data.data.forEach((item) => {
        if (item.conversation.id !== null) {
          removedNulls.push(item);
        }
      });
      setMessages(removedNulls);
      setLoading(false);
      setErrorLoadingMessages(false);
    } else {
      setErrorLoadingMessages(true);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessages();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return {
    errorLoadingMessages,
    messages,
    loading,
    archiveMessageById,
    deleteMessageById,
  };
};

export default useMessages;
