import client from './client';

const getItems = (id) => client.get(`/shops/?categories=${id}`);

const rateItem = (data) => client.post('/ratings/add', data, { headers: { Accept: 'application/json' } });

export default {
  getItems,
  rateItem,
};
