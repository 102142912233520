import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: 100,
    background: '#fff',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    marginBottom: theme.spacing(2),
    '& img': {
      height: '100%',
      width: 100,
      objectFit: 'cover',
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  title: {
    color: '#000',
    '& span': {
      fontSize: 18,
      fontWeight: 600,
      marginLeft: theme.spacing(1),
    }
  },
  text: {
    color: '#c7754c',
    fontSize: 13,
  }
}));

const HistoriqueItem = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <img src={item.img} alt={item.title} />
      </div>
      <div className={classes.content}>
        <Typography className={classes.title}>
          #
          {item.id}
          <span>{item.title}</span>
        </Typography>
        <Typography className={classes.text}>{moment(item.date).format('DD/MM/YY')}</Typography>
        <Typography className={classes.text}>
          Résolu par
          {item.resolvedBy}
        </Typography>
      </div>
    </div>
  );
};

HistoriqueItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default HistoriqueItem;
