import authStorage from '../../localStorage/auth';
import authService from '../../services/authService';
import messageService from '../../services/messageService';

export const clearFirstRouteRequested = () => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_FIRST_ROUTE_REQUESTED' });
  };
};

export const setFirstRouteRequested = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FIRST_ROUTE_REQUESTED',
      data,
    });
  };
};

export const login = (data) => {
  return (dispatch) => {
    authStorage.storeToken(data.token);
    dispatch({
      type: 'LOGIN',
      data,
    });
  };
};

export const syncUser = () => {
  return async (dispatch) => {
    const result = await authService.syncUser();
    if (!result.ok) {
      authStorage.removeToken();
    }
    dispatch({
      type: 'SYNC',
      data: result.data.data,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    authStorage.removeToken();
    dispatch({ type: 'LOGOUT' });
  };
};

export const getUnreadMessagesCount = () => {
  return async (dispatch) => {
    const result = await messageService.getUnreadMessagesCount();
    if (!result.ok) {
      return; // #TODO handling error
    }
    dispatch({
      type: 'GET_UNREAD_MESSAGE_COUNT',
      data: result.data.count,
    });
  };
};

const initialState = {
  isProfileLoaded: true,
  unreadMessages: 0,
  firstRouteRequested: '',
  notifications: [
    {
      id: 1,
      message: 'changement des codes immeuble',
      date: '2020-12-07T00:00:00.000Z'
    },
    {
      id: 2,
      message: 'nouveau message reçu',
      date: '2020-12-07T00:00:00.000Z'
    },
    {
      id: 3,
      message: 'votre colis a été envoyé',
      date: '2020-12-07T00:00:00.000Z'
    },
    {
      id: 4,
      message: 'document uploadé',
      date: '2020-12-06T00:00:00.000Z'
    },
    {
      id: 5,
      message: 'donnez votre avis',
      date: '2020-12-06T00:00:00.000Z'
    }
  ],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        ...action.data,
        isProfileLoaded: true,
      };
    case 'SYNC':
      return {
        ...state,
        ...action.data,
        isProfileLoaded: true,
      };
    case 'FIRST_ROUTE_REQUESTED':
      return {
        ...state,
        firstRouteRequested: action.data,
      };
    case 'CLEAR_FIRST_ROUTE_REQUESTED':
      return {
        ...state,
        firstRouteRequested: '',
      };
    case 'LOGOUT':
      return initialState;
    case 'GET_UNREAD_MESSAGE_COUNT':
      return {
        ...state,
        unreadMessages: action.data,
      };
    case 'GET_BOOKMARKS':
      return {
        ...state,
        bookmarks: action.data,
      };
    case 'ADD_BOOKMARK':
      return {
        ...state,
        bookmarks: [
          ...state.bookmarks,
          action.data
        ],
      };
    case 'DELETE_BOOKMARK':
      return {
        ...state,
        bookmarks: state.bookmarks.filter((item) => item.id !== action.data),
      };
    default:
      return state;
  }
};

export default authReducer;
