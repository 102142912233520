import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  content: {
    width: '100%',
    marginLeft: theme.spacing(2),
    display: 'flex',
    border: '1px solid #fff',
    '& p': {
      textTransform: 'uppercase',
      padding: '10px 8px 8px 8px', // fix proxima nova uppercase bottom spacing bug
      borderLeft: 'none',
      flex: 1,
    },
    '& button': {
      borderLeft: '1px solid #fff',
      borderRadius: 0,
    },
    '& a': {
      borderLeft: '1px solid #fff',
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    }
  },
  dialogContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& embed': {
      flex: 1,
      marginBottom: theme.spacing(2),
    }
  },
  dialogHeader: {
    padding: '12px 0',
  }
}));

const MesDocumentsItem = ({ name, file }) => {
  const classes = useStyles();
  const [isPdfOpen, setIsPdfOpen] = useState(false);

  const closePdf = () => {
    setIsPdfOpen(false);
  };

  return (
    <div className={classes.item}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
        <path fill="none" stroke="#fff" strokeMiterlimit="20" d="M.5.5h22" />
      </svg>
      <div className={classes.content}>
        <Typography>{name}</Typography>
        {/*eslint-disable */
          /*
          <IconButton aria-label="view file" onClick={() => setIsPdfOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path fill="#fff" d="M14.723 18H.519c-.308 0-.494-.185-.494-.432V3.364c0-.247.186-.494.494-.494h8.152c.247 0 .432.186.432.494 0 .31-.185.433-.432.433H.95v13.277H14.23v-7.72c0-.247.185-.494.494-.494s.494.186.494.494v8.214c-.062.247-.247.432-.494.432z" />
              <path fill="#fff" d="M6.139 12.38a.472.472 0 0 1-.309-.123.485.485 0 0 1 0-.68L16.514.833a.485.485 0 0 1 .679 0 .485.485 0 0 1 0 .68L6.448 12.195a.34.34 0 0 1-.309.185z" />
              <path fill="#fff" d="M16.76 6.699c-.061 0-.061 0 0 0-.246 0-.493-.247-.493-.494l.123-4.632-4.631.186c-.247 0-.494-.186-.494-.433s.185-.494.432-.494L16.822.71c.124 0 .247.062.31.123.06.062.184.247.184.371l-.123 5.064a.422.422 0 0 1-.432.432z" />
            </svg>
          </IconButton>
          */
        /*eslint-disable */}
        <a href={file} download>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="#fff" d="M1.194 1.652h17.612v14.436h-5.949l2.362-3.06h-4.893V5.24h-.652v7.788H4.782l2.374 3.06H1.194zm4.893 12.029h3.587v.039h.652v-.04h3.588L10 18.698zM.542 16.74h7.103L10 19.76l2.349-3.02h7.11V1H.542z" />
            <path fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="20" strokeWidth=".2" d="M1.194 1.652h17.612v14.436h-5.949l2.362-3.06h-4.893V5.24h-.652v7.788H4.782l2.374 3.06H1.194zm4.893 12.029h3.587v.039h.652v-.04h3.588L10 18.698zM.542 16.74h7.103L10 19.76l2.349-3.02h7.11V1H.542z" />
          </svg>
        </a>
      </div>

      <Dialog
        fullScreen
        open={isPdfOpen}
        onClose={closePdf}
        aria-labelledby="view pdf"
      >
        <Container maxWidth="md" className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>
            <IconButton onClick={closePdf}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
                <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M13.653 1v0L.368 10.067v0l13.285 8.705v0" />
              </svg>
            </IconButton>
          </div>

          <embed src={file} type="application/pdf" />
        </Container>
      </Dialog>
    </div>
  );
};

MesDocumentsItem.propTypes = {
  name: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
};

export default MesDocumentsItem;
