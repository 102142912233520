import client from './client';

const getGestionnaires = (spotId) => client.get(`/spots/manager?spot_id=${spotId}`, {}, { headers: { Accept: 'application/json' } });

const getAllRecipients = () => client.get('/messages/getAllRecipients', {}, { headers: { Accept: 'application/json' } });

const getCodes = (spotId) => client.get(`/hostings/${spotId}`);

const getHostingInfo = (hostingId) => client.get(`/hostings/${hostingId}`);

export default {
  getHostingInfo,
  getGestionnaires,
  getCodes,
  getAllRecipients,
};
