import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import GoogleMap from '../GoogleMap';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogHeader: {
    padding: '12px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg.MuiSvgIcon-root': {
      width: 14,
      height: 14,
    },
    '& .MuiRating-root': {
      color: '#c7754c',
    },
    '& .MuiRating-iconEmpty': {
      color: '#c7754c',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      fontSize: 18,
      fontWeight: 600,
    }
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: 128,
    '& p': {
      fontSize: 13,
      lineHeight: 1.25,
    }
  },
  bottomSection: {
    background: '#c7754c',
    flex: 1,
  }
}));

const MesDocumentsItem = ({ item, isOpen, close }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      aria-labelledby="view pdf"
    >
      <Container maxWidth="md" className={classes.dialogContainer}>
        <div className={classes.dialogHeader}>
          <IconButton onClick={close}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
              <path fill="none" stroke="#c7754c" strokeMiterlimit="20" d="M13.653 1v0L.368 10.067v0l13.285 8.705v0" />
            </svg>
          </IconButton>
        </div>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
              <path fill="none" stroke="#000" strokeMiterlimit="20" d="M.5.5h22" />
            </svg>
            <Typography>{item.name}</Typography>
          </div>
          {
            /* eslint-disable */
            /*<Rating
              readOnly
              name={`${item.name} rating`}
              defaultValue={item.rating}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />*/
            /* eslint-disable */
          }
        </div>
        <div className={classes.content}>
          <Typography>{item.type}</Typography>
          <Typography>{item.street}</Typography>
          {/*
            <Typography>Ouverture :</Typography>
            <Typography>{`Du mardi au dimanche: ${item.openingHours}`}</Typography>
          */}
        </div>
      </Container>
      <div className={classes.bottomSection}>
        <Container maxWidth="md">
          <GoogleMap position={item.coordinates} />
        </Container>
      </div>
    </Dialog>
  );
};

MesDocumentsItem.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default MesDocumentsItem;
