import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  ActionAnimations,
  SwipeableList,
  SwipeableListItem
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import SecondaryLayout from '../../layouts/SecondaryLayout';
import Message from '../../components/Message';
import './Messages.css';

import useMessages from '../../hooks/useMessages';
import { getUnreadMessagesCount } from '../../redux/reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  messagesContainer: {
    height: 'calc(100vh - 401px)', // 361
    overflowY: 'scroll',
    background: '#fff',
    marginTop: '-54px',
  },
  linkToContact: {
    display: 'block',
    padding: theme.spacing(6),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    width: '100%',
    background: '#c7754c',
    color: '#fff',
    fontSize: 20,
    lineHeight: 1.25,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: '"Proxima Nova",-apple-system,BlinkMacSystemFont,"Segoe UI",sans-serif',
  },
  swipeContent: {
    color: '#fff',
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  swipeRight: {
    background: '#c7754c',
  },
  swipeLeft: {
    background: '#ff6e6e',
    justifyContent: 'flex-end',
  },
  noContent: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    background: '#fff',
  }
}));

const MessagesView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    errorLoadingMessages,
    messages,
    loading,
    archiveMessageById,
    deleteMessageById
  } = useMessages();
  const currentUserId = useSelector((state) => state.auth.user?.id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [topicToDelete, setTopicToDelete] = useState(null);
  const [swipeRefIndex, setSwipeRefIndex] = useState(null);
  const swipeLeftRefs = useRef([]);
  swipeLeftRefs.current = messages.map((_, i) => swipeLeftRefs.current[i] ?? React.createRef());

  // This is needed to keep track of swipe progress
  // and pass a prop to swipe items of the current status.
  // With this, there's a way to decide if a click event should navigate to
  // the message view or stay in the same page if a swipe is in progress.
  const [swipeProgress, handleSwipeProgress] = useState(0);
  const handleSwipeEnd = () => {
    setTimeout(() => {
      handleSwipeProgress(0);
    }, 250);
  };

  /*
  const swipeRightOptions = (id) => ({
    content: <div
    className={`${classes.swipeContent}
    ${classes.swipeRight}`}>
    <Typography>ARCHIVER</Typography></div>,
    actionAnimation: ActionAnimations.REMOVE,
    action: () => archiveMessageById(id)
  });
  */

  const openDeleteModal = (topic, i) => {
    setTopicToDelete(topic);
    setIsDeleteModalOpen(true);
    setSwipeRefIndex(i);
  };

  const closeDeleteModal = () => {
    setTopicToDelete(null);
    setIsDeleteModalOpen(false);
    swipeLeftRefs.current[swipeRefIndex].current.playReturnAnimation();
    setSwipeRefIndex(null);
  };

  const handleDeleteTopic = () => {
    deleteMessageById(topicToDelete.topic);
    setTopicToDelete(null);
    setIsDeleteModalOpen(false);
    setSwipeRefIndex(null);
    dispatch(getUnreadMessagesCount());
  };

  const swipeLeftOptions = (item, i) => ({
    content: <div className={`${classes.swipeContent} ${classes.swipeLeft}`}><Typography>Delete</Typography></div>,
    actionAnimation: ActionAnimations.REMOVE,
    action: () => openDeleteModal(item, i)
  });

  const threshold = 0.33;
  const transitionTimeout = 2500;

  return (
    <SecondaryLayout
      title="Vos messages"
    >
      <div className={classes.messagesContainer}>
        {errorLoadingMessages && (
          <div className={classes.noContent}>
            <Typography>
              Une erreur s&apos;est produite lors de la récupération des messages
            </Typography>
          </div>
        )}
        {(!messages.length && !loading) && (
          <div className={classes.noContent}>
            <Typography>Vous n&apos;avez aucun message.</Typography>
          </div>
        )}
        <SwipeableList threshold={threshold}>
          {({
            className,
            scrollStartThreshold,
            swipeStartThreshold,
          }) => (
            <TransitionGroup
              className={className}
              enter
              exit
            >
              {messages.map((item, i) => (
                <CSSTransition
                  classNames="my-node"
                  key={item.topic}
                  timeout={transitionTimeout}
                >
                  <SwipeableListItem
                    ref={swipeLeftRefs.current[i]}
                    key={item.topic}
                    onSwipeProgress={handleSwipeProgress}
                    onSwipeEnd={handleSwipeEnd}
                    scrollStartThreshold={scrollStartThreshold}
                    swipeStartThreshold={swipeStartThreshold}
                    threshold={threshold}
                    swipeLeft={swipeLeftOptions(item, i)}
                    // swipeRight={swipeRightOptions(item.topic)}
                  >
                    <Message
                      key={item.topic}
                      currentUserId={currentUserId}
                      deleteMessageById={deleteMessageById}
                      message={item}
                      swipe={swipeProgress}
                    />
                  </SwipeableListItem>
                </CSSTransition>
              ))}
            </TransitionGroup>
          )}
        </SwipeableList>

      </div>
      <Link
        to="new"
        className={classes.linkToContact}
      >
        écrire à votre gestionnaire
      </Link>

      <Dialog
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
      >
        <DialogTitle>
          {topicToDelete && `Supprimer "${topicToDelete.conversation.subject}"`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous supprimerez ce message pour les deux utilisateurs
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteTopic} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </SecondaryLayout>
  );
};

export default MessagesView;
