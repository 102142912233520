import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ExpandedContent from './ExpandedContent';

import RatingDialog from './RatingDialog';
import bookmarkService from '../../services/bookmarkService';

const Accordion = withStyles({
  root: {
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    marginBottom: 16,
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    height: 100,
    padding: 0,
    '&$expanded': {
      boxShadow: '0 3px 6px rgba(0,0,0,.16)',
      height: 100,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    minWidth: 0,
    '&$expanded': {
      margin: 0,
      minWidth: 0,
      '& img': {
        width: '100%',
      },
      '& img + div': {
        display: 'none',
      },
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    background: '#c7754c',
    color: '#fff',
    padding: theme.spacing(3),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    width: '100%',
    padding: theme.spacing(2),
    minWidth: 0,
  },
  summaryTopSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summaryImage: {
    height: 100,
    width: 110,
    objectFit: 'cover',
    transition: '.25s ease',
    flexShrink: 0,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    '& svg': {
      flexShrink: 0,
      marginRight: theme.spacing(1),
    }
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: 18,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  summaryfavoriteBtn: {
    marginLeft: theme.spacing(1),
    marginRight: -8,
  },
  summaryText: {
    color: '#c7754c',
    fontSize: 13,
    textTransform: 'capitalize',
  },
  summaryBottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg.MuiSvgIcon-root': {
      width: 14,
      height: 14,
    },
    '& .MuiRating-root': {
      color: '#c7754c',
    },
    '& .MuiRating-iconEmpty': {
      color: '#c7754c',
    },
  },
  summaryDistance: {
    fontSize: 13,
    color: '#e5bba7',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: 14,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
    }
  }
}));

const CustomizedAccordions = ({
  items, bookmarks, setBookmarks, updateRatedItem
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();

  const { user } = useSelector((state) => state.auth);

  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [itemToRate, setItemToRate] = useState();

  const handleOpenRating = (item) => {
    setIsRatingOpen(true);
    setItemToRate(item);
  };

  const handleCloseRating = () => {
    setIsRatingOpen(false);
    setItemToRate();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isBookmarked = (itemId) => {
    return bookmarks.some((bookmark) => bookmark.shopId === itemId);
  };

  const handleBookmark = async (bookmarkedItemId) => {
    const formData = new FormData();
    formData.append('user_id', user.id);
    formData.append('entity_id', bookmarkedItemId);
    formData.append('entity_name', 'Shops');
    const response = await bookmarkService.addBookmark(formData);
    if (!response.ok) {
      // console.log('error bookmarking item'); // #TODO handle errors
    }
    setBookmarks((prev) => {
      return [
        ...prev,
        { bookmarkId: response.data.bookmark_id, shopId: bookmarkedItemId }
      ];
    });
  };

  const handleDeleteBookmark = async (removedId) => {
    const removedBookmark = bookmarks.find((bookmark) => {
      return bookmark.shopId === removedId;
    });

    const response = await bookmarkService.deleteBookmark(removedBookmark.bookmarkId);
    if (!response.ok || !response.data?.success) {
      console.log('error removing bookmarking'); // #TODO handle errors
    }
    setBookmarks((prev) => {
      return prev.filter((prevItem) => prevItem.shopId !== removedId);
    });
  };

  return (
    <div>
      {items.map((item) => (
        <Accordion
          key={item.id}
          square
          expanded={expanded === `${item.name}-${item.id}`}
          onChange={handleChange(`${item.name}-${item.id}`)}
        >
          <AccordionSummary aria-controls={`${item.name}-${item.id}`}>
            <img className={classes.summaryImage} src={item.img} alt={item.name} />
            <div className={classes.summaryContainer}>
              <Box className={classes.summaryTopSection}>
                <div className={classes.titleContainer}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="1" viewBox="0 0 23 1">
                    <path fill="none" stroke="#000" strokeMiterlimit="20" d="M.5.5h22" />
                  </svg>
                  <Typography className={classes.summaryTitle}>{item.name}</Typography>
                </div>
                <IconButton
                  className={classes.summaryfavoriteBtn}
                  aria-label="favorite"
                  // eslint-disable-next-line
                  onClick={() => (isBookmarked(item.id) ? handleDeleteBookmark(item.id) : handleBookmark(item.id))}
                >
                  <img src={isBookmarked(item.id) ? '/static/icons/favoriteIcon.svg' : '/static/icons/bookmark-empty.svg'} alt="" />
                </IconButton>
              </Box>
              <Typography className={classes.summaryText}>{item.categories[0]}</Typography>
              <Box className={classes.summaryBottomSection}>
                <div onClick={() => handleOpenRating(item)} className={classes.ratingContainer}>
                  <Rating
                    readOnly
                    name={`${item.name} rating`}
                    value={item.rating ? item.rating : 0}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  />
                  <Typography>{`(${item.ratingVotes})`}</Typography>
                </div>
                <Typography className={classes.summaryDistance}>{`(${item.distance})`}</Typography>
              </Box>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ExpandedContent
              item={item}
              handleOpenRating={handleOpenRating}
              isBookmarked={isBookmarked(item.id)}
              handleBookmark={handleBookmark}
              handleDeleteBookmark={handleDeleteBookmark}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <RatingDialog
        item={itemToRate}
        isOpen={isRatingOpen}
        handleClose={handleCloseRating}
        updateRatedItem={updateRatedItem}
      />
    </div>
  );
};

CustomizedAccordions.propTypes = {
  items: PropTypes.array.isRequired,
  updateRatedItem: PropTypes.func,
  bookmarks: PropTypes.array,
  setBookmarks: PropTypes.func,
};

export default CustomizedAccordions;
