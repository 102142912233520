import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getMenus, getHostingInfo } from '../redux/reducers/uiReducer';

const PropertyLayout = () => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { hostingId } = useParams();

  useEffect(() => {
    dispatch(getMenus(propertyId));
  }, [propertyId]);

  useEffect(() => {
    // console.log('hostingid', hostingId);
    dispatch(getHostingInfo(hostingId));
  }, [hostingId]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PropertyLayout;
