import { useEffect, useState } from 'react';
import categoryService from '../services/categoryService';

const useCategory = () => {
  const [listData, setListData] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [filters, setFilters] = useState(null);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const [categoryId, setCategoryId] = useState();
  const [sortBy, setSortBy] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [bookmarks, setBookmarks] = useState([]);

  const fetchItems = async (fetchId) => {
    const response = await categoryService.getItems(fetchId);
    // console.log('category response', response);
    if (!response.ok) {
      return setError('An error occurred while getting the list items.');
    }

    if (response.data.included) {
      if (Array.isArray(response.data.included)) {
        const filteredBookmarks = response.data.included.filter((item) => item.type === 'bookmarks');
        const formatedBookmarks = filteredBookmarks.map((item) => {
          return {
            bookmarkId: item.id,
            shopId: item.relationships.shop.data.id,
          };
        });
        setBookmarks(formatedBookmarks);
      }
    }

    setError('');
    const extractedCategoriesForFilters = [];
    const formatedListItems = response.data.data.map((item) => {
      const coords = item.attributes.coordinate.split(',');
      // get the categories for filters
      extractedCategoriesForFilters.push(...item.attributes.type);
      // construct each item object
      return {
        id: item.id,
        name: item.attributes.name,
        img: item.attributes.img ? item.attributes.img : '/static/images/foodItem.jpg',
        type: '',
        categories: item.attributes.type,
        rating: item.attributes.rateAvg,
        ratingVotes: item.attributes.rateCount,
        distance: item.attributes.distance,
        street: item.attributes.address,
        coordinates: { lat: Number(coords[0]), lng: Number(coords[1]) },
        openingHours: '11h30-15 / 18-00h',
        site: item.attributes.website,
        phone: item.attributes.phone
      };
    });

    // make a new array with unique filters
    const uniqueFiltersList = [...new Set(extractedCategoriesForFilters)];

    // construct object from filters array for creating a controlled checkbox group
    /*
      {
        restaurants: false,
        bars: false,
        fastFood: false,
        boulangeries: false,
        vegan: false,
      }
    */
    const filterObj = uniqueFiltersList.reduce((o, key) => ({ ...o, [key]: false }), {});

    setFilters(filterObj);
    setListData(formatedListItems);
    setLoading(false);
  };

  useEffect(() => {
    setSortBy('');
    if (categoryId) {
      fetchItems(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (filters) {
      // create an array with active filters keys ex: ['restaurants','vegan']
      const activeFiltersArr = Object.keys(filters).filter((k) => filters[k]);
      setTotalActiveFilters(activeFiltersArr.length);

      if (activeFiltersArr.length > 0) {
        const filteredItems = listData.filter((item) => {
          // checks if item categories array contains any value that match active filters array
          const categoriesMatch = activeFiltersArr.some((i) => item.categories.includes(i));
          return categoriesMatch ? item : null;
        });
        setListItems(filteredItems);
      } else {
        // no filters, set all items
        setListItems(listData);
      }
    }

    // Apply Sort
    if (sortBy === 'ASC') {
      setListItems((prevList) => [...prevList].sort((a, b) => (a.name < b.name ? -1 : 1)));
    } else if (sortBy === 'DESC') {
      setListItems((prevList) => [...prevList].sort((a, b) => (a.name > b.name ? -1 : 1)));
    }
  }, [filters, listData, sortBy]);

  return {
    loading,
    categoryId,
    setCategoryId,
    listItems,
    setListItems,
    filters,
    setFilters,
    totalActiveFilters,
    sortBy,
    setSortBy,
    bookmarks,
    setBookmarks,
    error,
  };
};

export default useCategory;
