import client from './client';

const getBookmarks = () => client.get('/bookmarks', {}, { headers: { Accept: 'application/json' } });

const addBookmark = (data) => client.post('/bookmarks/add', data, { headers: { Accept: 'application/json' } });

const deleteBookmark = (shopId) => client.delete(`/bookmarks/delete/${shopId}`, {}, { headers: { Accept: 'application/json' } });

export default {
  getBookmarks,
  addBookmark,
  deleteBookmark,
};
