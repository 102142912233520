import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SecondaryLayout from '../layouts/SecondaryLayout';
import AccordionWithImg from '../components/AccordionWithImg';
import FiltersPopover from '../components/FiltersPopover';
import CategoriesExternalLinks from '../components/CategoriesExternalLinks';

import useCategory from '../hooks/useCategory';
import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: -32,
    marginBottom: 7,
    display: 'flex',
    justifyContent: 'space-between',
  },
  filtersRightSide: {
    display: 'flex',
  },
}));

const CategoryView = () => {
  const classes = useStyles();
  const { category: categoryParam } = useParams();
  const [currentMenu, setCurrentMenu] = useState('');
  const [showFavorites, setShowFavorites] = useState(false);
  const menus = useSelector((state) => state.ui.menus);
  const {
    loading,
    categoryId,
    setCategoryId,
    listItems,
    setListItems,
    filters,
    setFilters,
    totalActiveFilters,
    sortBy,
    setSortBy,
    bookmarks,
    setBookmarks,
    error,
  } = useCategory();

  useEffect(() => {
    setCurrentMenu('');
    const thisMenu = menus?.find((item) => item.slug === categoryParam);
    setCurrentMenu(thisMenu);
    setCategoryId(thisMenu?.id);
  }, [categoryParam, menus]);

  const handleChangeFilters = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const handleSortItems = () => {
    setSortBy((prev) => {
      let newOrder;
      if (prev === '') {
        newOrder = 'ASC';
      } else if (prev === 'ASC') {
        newOrder = 'DESC';
      } else if (prev === 'DESC') {
        newOrder = '';
      }
      return newOrder;
    });
  };

  const updateRatedItem = (shopId, newRating, newTotalVotes) => {
    setListItems((prev) => prev.map((list) => {
      return (list.id === shopId)
        ? { ...list, rating: newRating, ratingVotes: newTotalVotes }
        : list;
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <SecondaryLayout
      breadcrumbTitle={currentMenu?.name}
      breadcrumbIcon={<img src={currentMenu?.icon} alt="" />}
      title={currentMenu?.pageTitle}
      backBtnHistory="history"
    >
      <div className={classes.filterContainer}>
        <FiltersPopover
          totalActiveFilters={totalActiveFilters}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
        />
        <div className={classes.filtersRightSide}>
          <IconButton aria-label="sort" onClick={() => handleSortItems()}>
            {!sortBy && <img height="15" width="19" src="/static/icons/sortIcon.svg" alt="sort" />}
            {sortBy === 'ASC' && <img height="15" width="19" src="/static/icons/sortIconAsc.svg" alt="sort" />}
            {sortBy === 'DESC' && <img height="15" width="19" style={{ transform: 'rotate(180deg)' }} src="/static/icons/sortIconAsc.svg" alt="sort" />}
          </IconButton>
          <IconButton
            aria-label="favorite"
            onClick={() => setShowFavorites((prevState) => !prevState)}
          >
            <img src={showFavorites ? '/static/icons/favoriteIcon.svg' : '/static/icons/bookmark-empty.svg'} alt="" />
          </IconButton>
          <CategoriesExternalLinks categoryId={categoryId} />
        </div>
      </div>

      {error ? (
        <Typography>{error}</Typography>
      ) : (
        <AccordionWithImg
          items={!showFavorites ? listItems : listItems.filter((listItem) => {
            return bookmarks.filter((bookmark) => {
              return bookmark.shopId === listItem.id;
            }).length !== 0;
          })}
          bookmarks={bookmarks}
          setBookmarks={setBookmarks}
          updateRatedItem={updateRatedItem}
        />
      )}

    </SecondaryLayout>
  );
};

export default CategoryView;
