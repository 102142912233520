import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import FiltersCheckboxes from './FiltersCheckboxes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  popoverContainer: {
    '& .MuiPopover-paper': {
      border: '1px solid #E5BBA7',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    }
  }
}));

const FiltersPopover = ({ filters, handleChangeFilters, totalActiveFilters }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-filter-btn' : undefined;

  return (
    <div className={classes.root}>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={totalActiveFilters} color="primary">
          <img src="/static/icons/filterIcon.svg" alt="filter" />
        </Badge>
      </IconButton>
      <Popover
        className={classes.popoverContainer}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FiltersCheckboxes filters={filters} handleChangeFilters={handleChangeFilters} />
      </Popover>
    </div>
  );
};

FiltersPopover.propTypes = {
  filters: PropTypes.object,
  handleChangeFilters: PropTypes.func.isRequired,
  totalActiveFilters: PropTypes.number.isRequired,
};

export default FiltersPopover;
