import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

// Inspired by blueprintjs
function CustomCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      checkedIcon={(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="3" viewBox="0 0 34 3">
          <defs><clipPath id="m5ipa"><path fill="#fff" d="M0 3V0h34v3z" /></clipPath></defs>
          <path fill="#ce9578" d="M0 3V0h34v3z" />
          <path fill="none" stroke="#e5bba7" strokeMiterlimit="20" d="M0 3v0-3 0h34v3z" clipPath="url(&quot;#m5ipa&quot;)" />
        </svg>
      )}
      icon={(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="3" viewBox="0 0 34 3">
          <defs><clipPath id="kutza"><path fill="#fff" d="M0 3V0h34v3z" /></clipPath></defs>
          <path fill="none" stroke="#e5bba7" strokeMiterlimit="20" d="M0 3v0-3 0h34v3z" clipPath="url(&quot;#kutza&quot;)" />
        </svg>
      )}
      inputProps={{ 'aria-label': 'filter checkbox' }}
      {...props}
    />
  );
}

export default CustomCheckbox;
