import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHistory = () => {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    // split pathname to array and remove path section that contains view
    // ex: /messages/*view*/:someid should go back to /messages
    const locationArray = location.pathname.split('/').filter((pathSection) => pathSection !== 'view');
    // check for a trailing / in path and remove it
    const lastItem = locationArray[locationArray.length - 1];
    if (!lastItem) {
      const joinedPath = locationArray.slice(0, -2).join('/');
      setPreviousPath(joinedPath);
    } else {
      const joinedPath = locationArray.slice(0, -1).join('/');
      setPreviousPath(joinedPath);
    }
  }, [location]);

  return {
    previousPath,
  };
};

export default useHistory;
